import { useState } from 'react';
import styles from './index.module.scss';
import { useParams } from 'react-router';

export function CategoryChip({ category, onCategorySubmit }) {
  const { id, slug, image, name } = category;
  const params = useParams();
  const { slug: selectedSlug } = params;

  const handleToggleCategory = () => {
    onCategorySubmit(slug,name);
  };

  return (
    <div
      onClick={handleToggleCategory}
      className={[
        styles['category-chip'],
        selectedSlug === slug ? styles['category-chip-selected'] : ''
      ].join(' ')}
    >
      {name}
    </div>
  )
}

export default CategoryChip;
