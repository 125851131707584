import logo from '../../assets/images/navbarEnLogo.png';
import grayBorder from '../../assets/icons/gray-ticket-border.svg';
import gradientBorder from '../../assets/icons/gradient-ticket-border.svg';
import ticketBack from '../../assets/icons/ticket-back.svg';
import styles from './index.module.scss';

export function Ticket({number,selectedTickets,toogleSelectTicket}) {
	return (
		<div className={styles['ticket']}>
			<div
				onClick={() => {
					if (toogleSelectTicket) {
						toogleSelectTicket(number)
					}
				}}
				className={`${styles['ticket-front']} ${selectedTickets.includes(number) ? styles['ticket-selected'] : ''}`}
			>
				<img className={styles['ticket-border']} src={selectedTickets.includes(number) ? gradientBorder:grayBorder} alt='border'/>
				<span className={styles['ticket-header']}>
					<img src={logo} alt='logo' className={styles['ticket-header-logo']} />
				</span>
				<p className={styles['ticket-number']}>{number}</p>
			</div>
			<div className={styles['ticket-back']}>
				<img className={styles['ticket-back-image']} src={ticketBack} alt='kasbana logo' />
			</div>
		</div>
	)
}

export default Ticket;
