import styles from './index.module.scss';
import pending from '../../assets/images/pending.png';
import Button from '../formButton';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function PaymentPending() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleHomeNavigation = () => {
    navigate('/')
  };
  
  return (
    <div className={styles['pending']}>
      <div className={styles['pending-main']}>
        <img src={pending} className={styles['pending-image']} alt='pending' />
        <div className={styles['pending-content']}>
          <h3 className={styles['pending-content-header']}>{t('payment_pending.title')}</h3>
          <p className={styles['pending-content-text']}>{t('payment_pending.pending')}<br />{t('payment_pending.allow')}</p>
        </div>
      </div>
      <div className={styles['pending-actions']}>
        <Button handleSubmit={handleHomeNavigation} text={t('payment_pending.back_button')} variant={'outline'}/>
      </div>
    </div>
  )
}

export default PaymentPending;
