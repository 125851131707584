import styles from './index.module.scss';
import Button from '../formButton';
import close from '../../assets/icons/close.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import upload from '../../assets/icons/upload.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { retriveProfile } from '../../services/profileService';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from '../../store/slices/userSlice';
import ProgressBar from '../../components/progressBar';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function ImageUpload({setShowModal, handleModalClose}) {
  const [file, setFile] = useState();
  const [fileShow, setFileShow] = useState();
  const [progress, setProgress] = useState(0);
  const user = useSelector(state => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileShow(URL.createObjectURL(e.target.files[0]))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    let payload = {}
    if (file) {
      formData.append("profile_picture", file);
    } else if (!fileShow) {
      payload['profile_picture'] = null
    }
    const response = file ? await axios({
      method: "patch",
      url: '/api/account/profile/',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${user.token}`
      },
      onUploadProgress: (progressEvent) => {
        setProgress(Math.floor(progressEvent.progress * 100))
      },
    }) : await axios({
      method: "patch",
      url: '/api/account/profile/',
      data: payload,
      headers: {
        'Authorization': `Token ${user.token}`
      },
    });
    if (response.status === 200) {
      const profileResponse = await retriveProfile();
      if (profileResponse.status === 200) {
        setShowModal(false);
        setProgress(0)
        dispatch(setProfile(profileResponse.data));
      } else if (profileResponse.status === 401) {
        navigate('/login')
        // handle error
      }
    } else if (response.status === 401) {
      navigate('/login')
      // handle error
    }
  }
  const handleDeleteImage = () => {
    setFile(null);
    setFileShow(null)
  }

  useEffect(() => {
    if (user.profile?.profile_picture) {
      setFileShow(user.profile.profile_picture)
    }
  },[])
  
  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-header']}>
          <img onClick={handleModalClose} src={close} alt='close' className={styles['modal-header-close']} />
          <h3 className={styles['modal-header-text']}>{t('my_profile.edit_photo')}</h3>
        </div>
        <form onSubmit={handleSubmit} className={styles['modal-content']}>
          {fileShow ?
            <div className={styles['modal-image']}>
              <div onClick={handleDeleteImage} className={styles['modal-image-delete-container']}>
                <img  className={styles['modal-image-delete']} alt='delete' src={deleteIcon} />
              </div>
              <div className={styles['progress-container']}>
                <ProgressBar progress={progress} />
              </div>
              <img src={fileShow} className={styles['modal-image-show']} alt='' />
            </div> :
            <label className={styles['modal-image-input-label']}>
              <img src={upload} className={styles['modal-image-input-label-image']} alt='upload image' />
              <input className={styles['modal-image-input']} type='file' accept="image/*" onChange={handleChange}/>
            </label>
          }
          <Button text={t('my_profile.save_button')} type={'submit'} />
        </form>
      </div>
    </div>
  )
}

export default ImageUpload;