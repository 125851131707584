import styles from './index.module.scss';
import Button from '../formButton';
import winner from '../../assets/images/winner.gif';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';

const colors = [
  '#a51d5d',
  '#f49c26',
  '#b46926',
  '#ed7443',
  '#b25237',
  '#e95c54',
  '#ab324a',
  '#e13a6c',
]

export function WinnerModal({name,onModalSubmit}) {
  const {t} = useTranslation();
  
  return (
    <>
      <Confetti width={window.innerWidth} colors={colors} initialVelocityY={20} tweenDuration={1000} numberOfPieces={1000}/>
      <div className={styles['modal']}>
        <div className={styles['modal-container']}>
          <img src={winner} alt='winner' className={styles['modal-image']} />
          <div className={styles['modal-content']}>
            <p className={styles['modal-text']}>{t('live_wheel.congratulations')}</p>
            <p className={styles['modal-text-secondary']}>{t('live_wheel.you_won')} {name} {t('live_wheel.claim_it')}</p>
            <Button handleSubmit={onModalSubmit} text={t("live_wheel.back")} type={'button'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default WinnerModal;