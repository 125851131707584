import CategoryHeader from '../../components/categoryHeader';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import styles from './index.module.scss';

export function Layout({ page, children }) {
  const applyPadding = () => {
    switch (page) {
      case 'terms-of-use':
        return false;
      case 'help-and-support':
        return false;
      case 'category':
        return false;
      default:
        return true;
    }
  };
  return (
    <>
      <Navbar />
      <CategoryHeader />
      <main className={applyPadding() ? styles['layout-main'] : ''}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout;