import axios from 'axios';
import EgyptLogo from '../../assets/icons/egypt-icon-rounded.svg';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

export function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const changeLanguage = () => {
    if (i18n.language === 'en-US') {
      i18n.changeLanguage('ar-EG');
      document.dir = 'rtl';
      document.documentElement.lang = 'ar';
      axios.defaults.headers.common['Accept-Language'] = 'ar';
    } else {
      i18n.changeLanguage('en-US');
      document.dir = 'ltr';
      document.documentElement.lang = 'en';
      axios.defaults.headers.common['Accept-Language'] = 'en';
    }
  };
  
  return (
    <button className={styles['language-switcher']} type='button' onClick={changeLanguage}>
      {i18n.language === 'en-US' && (
        <>
          <span className={styles['language-switcher-text']}>العربيه</span>
          <img className={styles['language-switcher-image']} src={EgyptLogo} alt='egypt flag'/>
        </>
      )}
      {i18n.language === 'ar-EG' && (
        <>
          <img className={styles['language-switcher-image']} src={EgyptLogo} alt='egypt flag'/>
          <span className={styles['language-switcher-text']}>English</span>
        </>
      )}
    </button>
  )
}

export default LanguageSwitcher;
