import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
	name: 'loading',
	initialState: false,
	reducers: {
		setLoading: (state, action) => {
			return action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
