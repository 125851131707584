import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const sendOTP = (payload) => {
	return axios
		.post('auth/send_otp', payload)
		.then((res) => res)
		.catch((err) => err?.response);
};

export const checkToken = (payload) => {
	return axios
		.post('/api/account/check_token/', payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((res) => res)
		.catch((err) => err?.response);
};

export const logout = () => {
	return axios
		.post('/auth/logout', undefined, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const forgetPassword = (payload) => {
	return axios
		.post('auth/forgot_password', payload)
		.then((res) => res)
		.catch((err) => err?.response);
};
