import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Signup from './pages/signup';
import Login from './pages/login';
import ProtectedRoute from './utils/ProtectedRoute';
import ProductDetails from './pages/productDetails';
import Layout from './layout/layout';
import ProtectedRouteLayout from './layout/protectedRouteLayout';
import Checkout from './pages/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { addOrder } from './store/slices/orderSlice';
import PaymentStatus from './pages/payment-status';
import LiveWheel from './pages/liveWheel';
import TermsOfUse from './pages/termsOfUse';
import HelpAndSupport from './pages/helpAndSupport';
import PrivacyAndPolicy from './pages/privacyAndPolicy';
import { useEffect } from 'react';
import { getFavouriteProducts } from './services/sharedService';
import { setFavourites } from './store/slices/favouritesSlice';
import Category from './pages/category';
import MyProfile from './pages/myProfile';
import Tab from './components/tab';
import { checkToken } from './services/authSharedService';
import { setUser } from './store/slices/userSlice';
import { setLoading } from './store/slices/loadingSlice';
import Loading from './components/loading';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function App() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const { token } = user;
	const { i18n } = useTranslation();
	const loading = useSelector((state) => state.loading);

	const getLayout = (path, component) => {
		const authRoutes = ['signup', 'login'];
		const protectedRoutes = ['checkout', 'payment-status', 'live-wheel', 'my-profile'];
		if (authRoutes.includes(path)) {
			return component;
		} else if (protectedRoutes.includes(path)) {
			return <ProtectedRouteLayout page={path}>{component}</ProtectedRouteLayout>;
		} else {
			return <Layout page={path}>{component}</Layout>;
		}
	};
	const handleGetFavourites = async () => {
		const response = await getFavouriteProducts();
		if (response.status === 200) {
			dispatch(setFavourites(response.data));
		}
	};
	const handleTokenAuth = async () => {
		dispatch(setLoading(true));
		const response = await checkToken({ token: user.token });
		if (response.status === 200) {
			if (response.data.exists) {
				handleGetFavourites();
			} else {
				dispatch(setUser({ token: '', user: null, profile: null }));
			}
		}
		setTimeout(() => {
			dispatch(setLoading(false));
		}, 1000);
	};

	useEffect(() => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response.status === 401) {
					dispatch(setUser({ token: '', user: null, profile: null }));
				}
				return error;
			}
		);
		dispatch(addOrder(null));
		if (i18n.language === 'en-US') {
			i18n.changeLanguage('en-US');
			document.dir = 'ltr';
			document.documentElement.lang = 'en';
			axios.defaults.headers.common['Accept-Language'] = 'en';
		} else {
			i18n.changeLanguage('ar-EG');
			document.dir = 'rtl';
			document.documentElement.lang = 'ar';
			axios.defaults.headers.common['Accept-Language'] = 'ar';
		}
	}, []);

	useEffect(() => {
		if (user?.token) {
			handleTokenAuth();
		}
	}, [token]);

	return (
		<>
			{loading && <Loading />}
			{!loading && (
				<>
					<BrowserRouter>
						<ProtectedRoute>
							<Routes>
								<Route path='/' element={getLayout('home', <Home />)} />
								<Route path='/product/:slug' element={getLayout('product', <ProductDetails />)} />
								<Route path='/signup' element={getLayout('signup', <Signup />)} />
								<Route path='/login' element={getLayout('login', <Login />)} />
								<Route path='/checkout' element={getLayout('checkout', <Checkout />)} />
								<Route path='/terms-of-use' element={getLayout('terms-of-use', <TermsOfUse />)} />
								<Route path='/privacy-and-policy' element={getLayout('terms-of-use', <PrivacyAndPolicy />)} />
								<Route path='/help-and-support' element={getLayout('help-and-support', <HelpAndSupport />)} />
								<Route path='/payment-status' element={getLayout('payment-status', <PaymentStatus />)} />
								<Route path='/my-profile' element={getLayout('my-profile', <MyProfile />)}>
									<Route path=':tab' element={<Tab />} />
								</Route>
								<Route path='/live-wheel/:id' element={getLayout('live-wheel', <LiveWheel />)} />
								<Route path='/category/:slug' element={getLayout('category', <Category />)} />
							</Routes>
						</ProtectedRoute>
					</BrowserRouter>
				</>
			)}
		</>
	);
}

export default App;
