import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { getWalletHistory, rechargeBalance } from '../../services/tabServices';
import { useDispatch, useSelector } from 'react-redux';
import topUpBg from '../../assets/images/top-up-bg.png';
import topUpBg2 from '../../assets/images/top-up-bg-2.gif';
import { getPaymentMethods } from '../../services/checkoutService';
import Radio from '../radio';
import Button from '../formButton';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

const amounts = [50, 100, 150, 200, 250, 500];

export default function TopUpTab() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [paymentMethods, setPaymentMethods] = useState(null);
	const [selectedPayment, setSelectedPayment] = useState(null);
	const [selectedAmount, setSelectedAmount] = useState();
	const [customValue, setCustomValue] = useState();
	const { profile } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleChange = (e) => {
		setSelectedAmount(null);
		const value = e.target.value.replace(/\D/g, '');
		setCustomValue(value);
	};
	const handleGetPaymentMethods = async () => {
		const response = await getPaymentMethods();
		if (response.status === 200) {
			setPaymentMethods(response.data);
			setSelectedPayment(response.data[0].slug);
		}
	};
	const handlePaymentSelect = (slug) => {
		setSelectedPayment(slug);
	};
	const handleTopUp = async (amount) => {
		setSelectedAmount(amount);
	};
	const handleGetWalletHistory = async () => {
		setLoading(true);
		const response = await getWalletHistory('balance');
		if (response.status === 200) {
			setData(response.data);
		}
		setLoading(false);
	};
	const handleRecharge = async (amount) => {
		const payload = {
			amount,
			is_web: true,
			payment_method: selectedPayment,
		};
		const response = await rechargeBalance(payload);
		if (response.status === 200) {
			window.location.href = response.data.iframe;
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.amount[0],
				})
			);
		}
	};
	const handlePay = async () => {
		if (selectedAmount) {
			await handleRecharge(Number(selectedAmount));
		} else if (customValue) {
			await handleRecharge(Number(customValue));
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: 'you must select an amount first',
				})
			);
		}
	};

	useEffect(() => {
		handleGetWalletHistory();
		handleGetPaymentMethods();
	}, []);

	if (data && !loading) {
		return (
			<div className={styles['top-up']}>
				<h3 className={styles['top-up-text']}>{t('my_profile.available-balance')}</h3>
				<div className={styles['top-up-header-container']}>
					<img src={topUpBg} alt='' />
					<h3 className={styles['top-up-header']}>
						{profile.available_balance} {t('currency')}
					</h3>
					<img src={topUpBg2} alt='' />
				</div>
				<p className={styles['top-up-hint']}>{t('my_profile.topup_hint')}</p>
				<div className={styles['top-up-amount']}>
					{amounts.map((amount) => (
						<button
							key={amount}
							onClick={() => handleTopUp(amount)}
							className={`${styles['top-up-amount-button']} ${selectedAmount === amount ? styles['top-up-amount-button-selected'] : ''}`}>
							{amount}
						</button>
					))}
				</div>
				<div className={styles['top-up-custom']}>
					<h3 className={styles['top-up-text']}>{t('my_profile.custom')}</h3>
					<input
						onChange={handleChange}
						value={customValue}
						className={styles['top-up-custom-input']}
						placeholder={t('my_profile.enter_amount')}
						type='text'
					/>
				</div>
				<div className={styles['top-up-pay']}>
					<h3 className={styles['top-up-pay-text']}>{t('my_profile.pay_with')}</h3>
					<div>
						{paymentMethods &&
							paymentMethods
								.filter((payment) => !payment.is_disabled && payment.slug !== 'topup')
								.map((payment) => (
									<Radio
										key={payment.id}
										selected={selectedPayment}
										handleClick={handlePaymentSelect}
										id={payment.id}
										name={'payments'}
										icons={payment.icons}
										value={payment.slug}
										label={payment.name}
									/>
								))}
					</div>
					<Button handleSubmit={handlePay} text={t('my_profile.proceed_button')} type={'button'} />
				</div>
			</div>
		);
	} else {
		return <Loader />;
	}
}
