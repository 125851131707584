import styles from './index.module.scss';
import fireIcon from '../../assets/icons/fireIcon.svg';
import fireWheel from '../../assets/images/fireWheel.png';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function FireWheel({product}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleProductNavigation = () => {
    navigate(`/product/${product.slug}`)
  }
  
  return (
    <div onClick={handleProductNavigation} className={styles['fire-wheel']}>
      <h3 className={styles['fire-wheel-header']}>{t('fire_wheel.title')}
        <img className={styles['fire-wheel-header-image']} src={fireIcon} alt='trending now' />
      </h3>
      <div className={styles['fire-wheel-product']}>
        <div className={styles['fire-wheel-product-container']}>
          <img src={product.thumbnail} className={styles['fire-wheel-product-image']} alt={product.name} />
          <p className={styles['fire-wheel-product-text']}>{product.name} {t('fire_wheel.wheel_on')} <span className={styles['fire-wheel-product-text-special']}>{t('fire_wheel.fire')}</span></p>
          <div className={styles['fire-wheel-image']}>
            <img src={fireWheel} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FireWheel;