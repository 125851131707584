import { useEffect, useState } from 'react';

export const useWidth = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (window.visualViewport) {
				if (window.visualViewport.width <= 768) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
			} else {
				if (window.innerWidth <= 768) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
			}
		};
		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isMobile;
};
