import styles from './index.module.scss';
import cart from '../../assets/icons/cart.svg';
import talabatTicket from '../../assets/images/talabat-ticket.png';
import Button from '../formButton';
import { useTranslation } from 'react-i18next';

export default function FailVoucherModal({onModalSubmit,onModalCancel,available,needed}) {
  const { t } = useTranslation();
  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-content']}>
          <img className={styles['modal-content-cart']} src={cart} alt='' />
          <p className={styles['modal-content-text']}>{t('my_profile.oops')}</p>
          <img className={styles['modal-content-ticket']} src={talabatTicket} alt='' />
          <div className={styles['modal-content-content']}>
            <p className={styles['modal-content-available']}>{t('my_profile.available_discount')} - <span>{available} {t('currency')}</span></p>
            <p className={styles['modal-content-needed']}>{t('my_profile.discount_needed')} - <span>{needed} {t('currency')}</span></p>
          </div>
          <p className={styles['modal-content-hint']}>{t('my_profile.buy_now')}</p>
          <div className={styles['modal-content-actions']}>
            <Button handleSubmit={onModalCancel} variant={'outline'} text={t('my_profile.cancel_button')} type={'button'} />
            <Button handleSubmit={onModalSubmit} text={t('my_profile.buy_button')} type={'button'} />
          </div>
        </div>
      </div>
    </div>
  )
}
