import { useTranslation } from 'react-i18next';
import HottestWheelCard from '../hottestWheelCard';
import styles from './index.module.scss';

export function HottestWheels({ products }) {
  const { t } = useTranslation();

  if (products) {
    return (
      <div className={styles['hottest-wheels']}>
        <h3 className={styles['hottest-wheels-header']}>{t('hottest_wheel.title')}</h3>
        <div className={styles['hottest-wheels-products']}>
          {products.map(product =>
            <div key={product.slug} className={styles['hottest-wheels-product']}>
              <HottestWheelCard product={product} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default HottestWheels;