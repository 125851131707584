export default function MyWheelsIcon ({color}) {
  return (
    <svg width="32" height="24" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.6875 8.4375H6.5625M10.3125 8.4375H12.1875M15.9375 8.4375H17.8125M4.6875 17.8125C4.6875 18.3098 4.88504 18.7867 5.23667 19.1383C5.58831 19.49 6.06522 19.6875 6.5625 19.6875H9.375C9.375 19.1902 9.57254 18.7133 9.92418 18.3617C10.2758 18.01 10.7527 17.8125 11.25 17.8125C11.7473 17.8125 12.2242 18.01 12.5758 18.3617C12.9275 18.7133 13.125 19.1902 13.125 19.6875H15.9375C16.4348 19.6875 16.9117 19.49 17.2633 19.1383C17.615 18.7867 17.8125 18.3098 17.8125 17.8125V4.6875C17.8125 4.19022 17.615 3.71331 17.2633 3.36168C16.9117 3.01004 16.4348 2.8125 15.9375 2.8125H13.125C13.125 3.30978 12.9275 3.78669 12.5758 4.13832C12.2242 4.48996 11.7473 4.6875 11.25 4.6875C10.7527 4.6875 10.2758 4.48996 9.92418 4.13832C9.57254 3.78669 9.375 3.30978 9.375 2.8125H6.5625C6.06522 2.8125 5.58831 3.01004 5.23667 3.36168C4.88504 3.71331 4.6875 4.19022 4.6875 4.6875L4.6875 17.8125Z" stroke="url(#paint0_linear_2652_67421)" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.2656 17.4375L23.6719 18.8438L26.4844 16.0312M30.7031 17.4375C30.7031 18.2685 30.5394 19.0914 30.2214 19.8592C29.9034 20.6269 29.4373 21.3245 28.8497 21.9122C28.262 22.4998 27.5644 22.9659 26.7967 23.2839C26.0289 23.6019 25.206 23.7656 24.375 23.7656C23.544 23.7656 22.7211 23.6019 21.9533 23.2839C21.1856 22.9659 20.488 22.4998 19.9003 21.9122C19.3127 21.3245 18.8466 20.6269 18.5286 19.8592C18.2106 19.0914 18.0469 18.2685 18.0469 17.4375C18.0469 15.7592 18.7136 14.1496 19.9003 12.9628C21.0871 11.7761 22.6967 11.1094 24.375 11.1094C26.0533 11.1094 27.6629 11.7761 28.8497 12.9628C30.0364 14.1496 30.7031 15.7592 30.7031 17.4375Z" stroke="url(#paint1_linear_2652_67421)" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id="paint0_linear_2652_67421" x1="4.6638" y1="11.9626" x2="17.5219" y2="11.0456" gradientUnits="userSpaceOnUse">
      <stop stopColor={color ?? "#FFD16A"}/>
      <stop offset="0.16" stopColor={color ?? "#F5A71F"}/>
      <stop offset="1" stopColor={color ?? "#DC1586"}/>
      </linearGradient>
      <linearGradient id="paint1_linear_2652_67421" x1="23.8405" y1="11.0865" x2="24.9738" y2="23.4445" gradientUnits="userSpaceOnUse">
      <stop stopColor={color ?? "#FFD16A"}/>
      <stop offset="0.16" stopColor={color ?? "#F5A71F"}/>
      <stop offset="1" stopColor={color ?? "#DC1586"}/>
      </linearGradient>
      </defs>
    </svg>
  )
}