import { useState } from 'react';
import styles from './index.module.scss';

export default function SwipeToUse({onFinish,disable}) {
  const [swiperValue, setSwiperValue] = useState(0);
  const [timerId, setTimerId] = useState(null);

  const handleInput = (e) => {
    setSwiperValue(e.target.value);
    if (timerId) {
      clearTimeout(timerId);
    };
    const timer = setTimeout(() => {
      handleSwipeFinish();
      clearTimeout(timer);
    }, 500);
    setTimerId(timer);
  }

  const handleSwipeFinish = () => {
    if(swiperValue >= 97) {
      onFinish();
      setSwiperValue(0);
    } else {
      setSwiperValue(0);
    }
  }

  return (
    <input disabled={disable} type='range' min={0} max={100} value={swiperValue} onInput={handleInput} className={styles['swipe']} />
  )
}
