import Cookies from 'js-cookie';

export const getCookie = (key, defaultValue) => {
	try {
		const serializedState = Cookies.get(key);
		if (serializedState === null) {
			return defaultValue;
		}
		return JSON.parse(serializedState);
	} catch (error) {
		return defaultValue;
	}
};

export const setCookie = (key, value) => {
	try {
		Cookies.set(key, JSON.stringify(value), { expires: 7 });
	} catch (error) {}
};

export const removeCookie = (key) => {
	try {
		Cookies.remove(key);
	} catch (error) {}
};
