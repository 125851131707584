import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
// import 'swiper/css/pagination';
import './index.css';

export function BigWinSlider({
  children,
}) {

  return (
    <div style={{position: 'relative'}}>
      <Swiper
        // install Swiper modules
        modules={[Pagination, A11y, Autoplay]}
        slidesPerView={1}
        breakpoints={{
          600: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1024: {
            slidesPerView:3
          },
          1400: {
            slidesPerView:4
          },
        }}
        loop={true}
        autoplay={{
          delay: 2000
        }}
        speed={1000}
        spaceBetween={20}
        className='big-win-slider'
        pagination={{ clickable: true }}
      >
        {children}
      </Swiper>
    </div>
  );
}

export default BigWinSlider;
