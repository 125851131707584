import axios from 'axios';

export const getProductByCategory = (slug, page, pageSize) => {
	if (slug === 'all') {
		return axios
			.get(`/api/ecommerce/products/?page=${page}&size=${pageSize}`)
			.then((response) => response)
			.catch((err) => err?.response);
	} else {
		return axios
			.get(`/api/ecommerce/products/?category__slug=${slug}&page=${page}&size=${pageSize}`)
			.then((response) => response)
			.catch((err) => err?.response);
	}
};
