import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { getStores, getVoucher } from '../../services/tabServices';
import talabatTicket from '../../assets/images/talabat-ticket.png';
import talabatTicketAr from '../../assets/images/talabat-ticket-ar.png';
import FilledStar from '../filledStar';
import list from '../../assets/icons/list.svg';
import sandClock from '../../assets/icons/sand-clock.svg';
import { useNavigate } from 'react-router';
import SwipeToUse from '../swipeToUse';
import SuccessVoucherModal from '../successVoucherModal';
import FailVoucherModal from '../failVoucherModal';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

export default function VoucherTab() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [voucher, setVoucher] = useState();
	const [available, setAvailable] = useState();
	const [needed, setNeeded] = useState();
	const [successModal, setSuccessModal] = useState(false);
	const [failModal, setFailModal] = useState(false);
	const [counter, setCounter] = useState(0);
	const [timerId, setTimerId] = useState(null);
	const [disable, setDisable] = useState(false);
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleGetStores = async () => {
		setLoading(true);
		const response = await getStores();
		if (response.status === 200) {
			if (response.data[0]) {
				setData(response.data[0]);
				if (response.data[0].have_active_voucher) {
					if (!timerId) {
						setCounter(response.data[0].next_available_voucher_time.hours * 3600 + response.data[0].next_available_voucher_time.minutes * 60);
						const id = setInterval(() => {
							setCounter((prev) => prev - 1);
						}, 60000);
						setTimerId(id);
					} else {
						clearInterval(timerId);
						setCounter(response.data[0].next_available_voucher_time.hours * 3600 + response.data[0].next_available_voucher_time.minutes * 60);
						const id = setInterval(() => {
							setCounter((prev) => prev - 1);
						}, 60000);
						setTimerId(id);
					}
				}
			}
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
		}
		setLoading(false);
	};

	const handleSwipeFinish = async () => {
		setDisable(true);
		const response = await getVoucher(data.id);
		if (response.status === 200) {
			setVoucher(response.data.vouchers);
			setSuccessModal(true);
			handleGetStores();
		} else {
			setAvailable(response.response.data.balance);
			setNeeded(response.response.data.required_balance);
			setFailModal(true);
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
		}
	};

	const handleTermsNavigation = () => {
		navigate('/terms-of-use');
	};

	const handleSuccessModalSubmit = () => {
		setSuccessModal(false);
	};
	const handleFailModalSubmit = () => {
		setDisable(true);
		setFailModal(false);
		navigate('/category/all');
	};
	const handleFailModalCancel = () => {
		setFailModal(false);
	};

	useEffect(() => {
		handleGetStores();
	}, []);

	if (data && !loading) {
		return (
			<>
				<div className={styles['discount-store']}>
					<img src={data.background_image} className={styles['discount-store-image']} alt='talabat' />
					<div className={styles['discount-store-header']}>
						<img src={data.logo} className={styles['discount-store-header-icon']} alt='talabat icon' />
						<div className={styles['discount-store-header-content']}>
							<h3 className={styles['discount-store-header-content-title']}>{data.name}</h3>
							<p className={styles['discount-store-header-content-text']}>{data.description}</p>
							<p className={styles['discount-store-header-content-text']}>
								<FilledStar />
								<span>({Number(data.rate).toLocaleString('en-US', { minimumFractionDigits: 1 })})</span>
							</p>
						</div>
					</div>
					<div className={styles['discount-store-balance']}>
						<p className={styles['discount-store-balance-title']}>{t('my_profile.available_discount_balance')}</p>
						<p className={styles['discount-store-balance-text']}>
							{data.user_balance} {t('currency')}
						</p>
					</div>
					<img
						className={[styles['discount-store-talabat-discount'], data.have_active_voucher ? styles['faded'] : ''].join(' ')}
						src={i18n.language !== 'ar-EG' ? talabatTicket : talabatTicketAr}
						alt='talabat discount'
					/>
					{data?.have_active_voucher && timerId && (
						<p className={styles['discount-store-hint-yellow']}>
							{t('my_profile.voucher_active_in')} {Math.floor(counter / 3600)} {t('my_profile.hours')}{' '}
							{Math.floor(counter / 60) - Math.floor(counter / 3600) * 60} {t('my_profile.minutes')}
						</p>
					)}
					<div onClick={handleTermsNavigation} className={styles['discount-store-data']}>
						<img src={list} className={styles['discount-store-data-icon']} alt='' />
						<p className={styles['discount-store-data-text']}>{t('my_profile.terms')}</p>
					</div>
					<div className={styles['discount-store-data']}>
						<img src={sandClock} className={styles['discount-store-data-icon']} alt='' />
						<p className={styles['discount-store-data-text']}>{t('my_profile.valid_till')}</p>
						{data?.have_active_voucher ? (
							<p className={styles['discount-store-data-text-hint']}>{t('my_profile.one_month')}</p>
						) : (
							<p className={styles['discount-store-data-date']}>{data?.current_valid_till?.split('-').reverse().join('/')}</p>
						)}
					</div>
					<p className={styles['discount-store-hint']}>
						{t('my_profile.swipe_to_use')} {t('currency')} {t('my_profile.from_you_balance')}
					</p>
					<div className={styles['discount-store-swiper']}>
						<SwipeToUse disable={disable || data?.have_active_voucher} onFinish={handleSwipeFinish} />
					</div>
				</div>
				{successModal && <SuccessVoucherModal onModalSubmit={handleSuccessModalSubmit} voucher={voucher} />}
				{failModal && (
					<FailVoucherModal available={available} needed={needed} onModalCancel={handleFailModalCancel} onModalSubmit={handleFailModalSubmit} />
				)}
			</>
		);
	} else {
		return <Loader />;
	}
}
