import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import HottestWheelCard from '../../components/hottestWheelCard';
import Pagination from '@mui/material/Pagination';
import { useLocation, useParams } from 'react-router';
import { getProductByCategory } from '../../services/categoryService';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';

export function Category() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalPages, setTotalPages] = useState(1);
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const { state } = useLocation();

  const handlePageChange = (e,value) => {
    setPage(value);
  };

  const handleGetCategoryProduct = async () => {
    setLoading(true);
    const response = await getProductByCategory(slug,page,pageSize);
    if (response.status === 200) {
      setProducts(response.data.results)
      setTotalPages(response.total_pages)
    } else {
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGetCategoryProduct();
  }, [slug, page]);

  if (loading) {
    return (
      <Loader/>
    )
  } else {
    return (
      <div className={styles['category-container']}>
        <h2 className={styles['category-header']}>{state?.name}</h2>
        <div className={styles['category']}>
          {products && products.map((product,index) =>
            <div className={styles['category-product']} key={index}>
              <HottestWheelCard product={product}/>
            </div>
          )}    
        </div>
        {/* <div className={styles['category-pagination']}>
          <Pagination count={totalPages} page={page} dir={i18n.language === 'en-US' ? 'ltr': 'rtl'} showFirstButton showLastButton onChange={handlePageChange}/>
        </div> */}
      </div>
    )
  }
}

export default Category;