import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { getTerms } from '../../services/sharedService';
import Loader from '../../components/loader';

export function TermsOfUse() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  
  const handleGetTerms = async () => {
    setLoading(true);
    const response = await getTerms();
    if (response.status === 200) {
      setData(response.data[0])
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetTerms()
  }, [])
  
  if (loading) {
    <Loader />
  }
  return (
    <div className={styles['terms-of-use']}>
      <h2 className={styles['terms-of-use-header']}>{data?.title}</h2>
      <div className={styles['terms-of-use-main']}>
        <div className={styles['terms-of-use-main-section']}>
          <h3 className={styles['terms-of-use-main-section-title']}>
            {data?.title}
          </h3>
          <div className={styles['terms-of-use-main-section-text']} dangerouslySetInnerHTML={{__html: data?.terms}} />
        </div>
        <div className={styles['terms-of-use-main-section']}>
          <h3 className={styles['terms-of-use-main-section-title']}>
            {data?.additional_title}
          </h3>
          <div className={styles['terms-of-use-main-section-text']} dangerouslySetInnerHTML={{__html: data?.additional_terms}}/>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse;
