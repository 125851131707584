import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const getProductBySlug = async (slug) => {
	return axios
		.get(`/api/ecommerce/products/${slug}`)
		.then((response) => response)
		.catch((err) => err?.response);
};

export const initiatePayment = async (slug, payload) => {
	return axios
		.post(`/api/ecommerce/products/${slug}/initiate-payment`, payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};
