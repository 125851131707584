import styles from './index.module.scss';
import selectArrow from '../../assets/icons/select-arrow.svg';
import { useState } from 'react';
import Radio from '../radio';

export default function Dropdown({ icon, label, options,name, selected, setSelected }) {
  const [open, setOpen] = useState(false);
  
  const handleToogle = () => {
    setOpen(prev => !prev);
  }
  const handleSelect = (slug) => {
    setSelected(slug)
    handleToogle();
  }
  return (
    <div className={`${styles['dropdown']} ${open ? styles['dropdown-open']: ''}`}>
      <div onClick={handleToogle} className={styles['dropdown-header']}>
        {icon && <img className={styles['dropdown-icon']} src={icon} alt='' />}
        <p className={styles['dropdown-label']}>{label}</p>
        <img className={`${open ? styles['dropdown-open-arrow'] : styles['dropdown-arrow']}`} src={selectArrow} alt='' />
      </div>
      {open &&
        <div className={styles['dropdown-content']}>
          <ul className={styles['dropdown-content-list']}>
            {options?.map(option => 
              <li key={option.id} className={styles['dropdown-content-list-item']}>
                <Radio  
                  key={option.id}
                  selected={selected} 
                  handleClick={handleSelect} 
                  id={option.id} 
                  value={option.name} 
                  name={name}
                  label={option.name} 
                ></Radio>
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  )
}
