import { useNavigate } from 'react-router';
import styles from './index.module.scss';
import Button from '../formButton';
import failure from '../../assets/images/failure.png';
import { useTranslation } from 'react-i18next';

export function PaymentFailure() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleHomeNavigation = () => {
    navigate('/')
  };
  
  return (
    <div className={styles['failure']}>
      <div className={styles['failure-main']}>
        <img src={failure} className={styles['failure-image']} alt='failure' />
        <div className={styles['failure-content']}>
          <h3 className={styles['failure-content-header']}>{t('payment_failure.title')}</h3>
          <p className={styles['failure-content-text']}>{t('payment_failure.description')}</p>
        </div>
      </div>
      <div className={styles['failure-actions']}>
        <Button handleSubmit={handleHomeNavigation} text={t('payment_failure.back_button')} variant={'outline'}/>
      </div>
    </div>
  )
}

export default PaymentFailure;