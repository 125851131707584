import styles from './index.module.scss';
import close from '../../assets/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import userIcon from '../../assets/icons/user.svg';
import avatar from '../../assets/icons/avatar.svg';
import emptyHeart from '../../assets/icons/empty-heart.svg'
import notificationsIcon from '../../assets/icons/notification.svg';
import balance from '../../assets/icons/navbar-balance.svg';
import plus from '../../assets/icons/plus.svg';
import backArrrow from '../../assets/icons/back-arrow.svg';
import logoutIcon from '../../assets/icons/logout-gradient.svg';
import LogoutModal from '../logoutModal';
import { setLoading } from '../../store/slices/loadingSlice';
import { setUser } from '../../store/slices/userSlice';
import { useState } from 'react';
import { logout } from '../../services/authSharedService';
import { useTranslation } from 'react-i18next';

export default function MobileMenu({ open, setOpen, handleCloseMenu, notifications, handleOpenNotifications }) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const user = useSelector(state => state.user);
  const categories = useSelector(state => state.categories);
  const favourites = useSelector(state => state.favourites);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoginNavigation = () => {
    setOpen(false);
    navigate('/login');
  };
  const handleTopup = () => {
    setOpen(false);
    navigate('/my-profile/top-up');
  };
  const handleGoToProfile = () => {
    setOpen(false);
    navigate('/my-profile/available-balance')
  };
  const handleGoToFavourites = () => {
    setOpen(false);
    navigate('/my-profile/favourites')
  }
  const handleCategorySubmit = (slug) => {
    setOpen(false);
    navigate(`/category/${slug}`)
  }
  const handleShowLogoutModal = () => {
    setOpen(false);
    setShowLogoutModal(true);
  }
  const handleCloseLogoutModal = () => {
    setOpen(false);
    setShowLogoutModal(false);
  }
  const handleLogout = async () => {
    const response = await logout();
    if (response.status === 200) {
      dispatch(setLoading(true));
      dispatch(setUser({ user: null, profile: null, token: '' }));
      const id = setTimeout(() => {
        navigate('/');
        dispatch(setLoading(false));
        clearTimeout(id)
      },1000)
    }
  }
  
  return (
    <>
      <div onClick={handleCloseMenu} className={`${styles['menu']} ${open ? styles['menu-open'] : ''}`}>
        <div onClick={(e) => e.stopPropagation()} className={styles['menu-container']}>
          <img onClick={handleCloseMenu} src={close} alt='close' className={styles['menu-close']} />
          <div className={styles['menu-container-header']}>
            {!user.user && (
              <div onClick={handleLoginNavigation} className={styles['menu-container-header-profile']}>
                <img className={styles['menu-container-header-logo']} src={userIcon} alt='sign-up' />
                <p className={styles['menu-container-header-text']}>{t('navbar.signup_signin')}</p>
              </div>
            )}
            {user.user && (
              <div onClick={handleGoToProfile} className={styles['menu-container-header-profile']}>
                {user.profile?.profile_picture ?
                  <img className={styles['menu-container-header-logo']} src={user.profile.profile_picture} alt={user.user.full_name} />:
                  <img className={styles['menu-container-header-logo']} src={avatar} alt='empty avatar' />
                }
                <p className={styles['menu-container-header-text']}>{t('navbar.hello')} {user.user.full_name.split(' ')[0]}</p>
              </div>
            )}
            <div className={styles['menu-container-header-actions']}>
              <div onClick={handleGoToFavourites} className={styles['menu-container-header-favourites']}>
                <img src={emptyHeart} className={styles['navbar-list-item-icon']} alt='' />
                {user.user && favourites.length > 0 ?
                  <p className={styles['menu-container-header-favourites-number']}>{favourites.length}</p>
                  : <></>
                }
              </div>
              <div onClick={handleOpenNotifications} className={styles['menu-container-header-favourites']}>
                <img src={notificationsIcon} className={styles['navbar-list-item-icon']} alt='' />
                {user.user && notifications?.filter(notification => !notification.read_at).length > 0 ?
                  <p className={styles['menu-container-header-favourites-number']}>{notifications.filter(notification => !notification.read_at).length}</p>
                  : <></>
                }
              </div>
            </div>
          </div>
          <div className={styles['menu-profile-actions']}>
            {user.profile &&
              <div onClick={handleGoToProfile} className={styles['menu-profile-balance']}>
                <p className={styles['menu-profile-balance-text']}>{t('navbar.balance')}</p>
                <p className={styles['menu-profile-balance-number']}><img src={balance} alt='' />{user.profile?.available_balance} {t('currency')}</p>
              </div>
            }
            {!user.profile &&
              <div onClick={handleLoginNavigation} className={styles['menu-profile-balance']}>
                <p className={styles['menu-profile-balance-text']}>{t('navbar.balance')}</p>
                <p className={styles['menu-profile-balance-number']}><img src={balance} alt='' />0 {t('currency')}</p>
              </div>
            }
            {user.profile &&
              <div onClick={handleTopup} className={styles['menu-profile-topup']}>
                <img src={plus} alt='' />
                <p className={styles['']}>
                  {t('navbar.topup')}
                </p>
              </div>
            }
            {!user.profile &&
              <div onClick={handleLoginNavigation} className={styles['menu-profile-topup']}>
                <img src={plus} alt='' />
                <p className={styles['']}>
                  {t('navbar.topup')}
                </p>
              </div>
            }
          </div>
          {categories.length > 0 &&
            <div className={styles['menu-categories']}>
              <h3 className={styles['menu-categories-header']}>
                {t('navbar.top_categories')}
              </h3>
              <ul className={styles['menu-categories-list']}>
                {categories.map(category => (
                  <li key={category.slug} onClick={()=>handleCategorySubmit(category.slug)} className={styles['menu-categories-list-item']}>{category.name}</li>
                ))}
              </ul>
              <button onClick={()=>handleCategorySubmit('all')} className={styles['menu-categories-action']}>{t('navbar.see_all')} <img src={backArrrow} alt='' /></button>
            </div>
          }
          {user.user && 
            <div className={styles['logout-container']}>
              <button onClick={handleShowLogoutModal} className={styles['logout']}>
                <img src={logoutIcon} alt='' />
                {t('navbar.logout')}
              </button>
            </div>
          }
        </div>
      </div>
      {showLogoutModal &&
        <LogoutModal handleLogout={handleLogout} handleModalClose={handleCloseLogoutModal} />
      }
    </>
  )
}
