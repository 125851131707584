import { useRef } from 'react';
import { Navigation, Pagination, FreeMode, A11y } from 'swiper/modules';
import { Swiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
// import 'swiper/css/pagination';
import './index.css';
import SliderArrow from './sliderArrow';

export function Slider({ slidesPerView, spaceBetween, children }) {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	return (
		<div style={{ paddingInline: '2rem', position: 'relative' }}>
			<Swiper
				modules={[Navigation, Pagination, A11y, FreeMode]}
				spaceBetween={spaceBetween}
				slidesPerView={slidesPerView}
				freeMode={true}
				className='default-slider'
				navigation={{
					prevEl: navigationPrevRef?.current,
					nextEl: navigationNextRef?.current,
				}}
				breakpoints={{
					0: {
						slidesPerView: 3,
						spaceBetween: 5,
					},
					350: {
						slidesPerView: 3,
						spaceBetween: 5,
					},
					445: {
						slidesPerView: 3,
						spaceBetween: 5,
					},
					650: {
						slidesPerView: 4,
						spaceBetween: 10,
					},
					769: {
						slidesPerView: 2,
						spaceBetween: 10,
					},
					870: {
						slidesPerView: 3,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 10,
					},
					1200: {
						spaceBetween: 10,
						slidesPerView: 5,
					},
				}}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef?.current;
					swiper.params.navigation.nextEl = navigationNextRef?.current;
				}}
				slidesPerGroup={slidesPerView}
				// pagination={{ clickable: true }}
			>
				{children}
				<div className='prev-button' ref={navigationPrevRef}>
					<SliderArrow color={'currentColor'} />
				</div>
				<div className='next-button' ref={navigationNextRef}>
					<SliderArrow color={'currentColor'} />
				</div>
			</Swiper>
		</div>
	);
}

export default Slider;
