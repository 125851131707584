import styles from './index.module.scss';
import Button from '../formButton';
import close from '../../assets/icons/close.svg';
import logout from '../../assets/icons/logout-modal.svg';
import { useTranslation } from 'react-i18next';

export function LogoutModal({handleLogout, handleModalClose}) {
  const { t } = useTranslation();

  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <img onClick={handleModalClose} src={close} alt='close' className={styles['modal-close']} />
        <img src={logout} alt='log out' />
        <h3 className={styles['modal-header']}>{t('navbar.logout')}</h3>
        <p className={styles['modal-text']}>{t('navbar.logout_description')}</p>
        <Button variant={'warning'} text={t('navbar.confirm_button')} handleSubmit={handleLogout}/>
        <Button type={'button'} text={t('navbar.cancel_button')} handleSubmit={handleModalClose}/>
      </div>
    </div>
  )
}

export default LogoutModal;