import styles from './index.module.scss';
import FormInput from '../formInput';
import { useHookForm } from '../../hooks/hook-form';
import emailIcon from '../../assets/icons/email.svg';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import FormTextarea from '../formTextarea';
import FormButton from '../formButton';
import { supportTicket } from '../../services/sharedService';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function HelpAndSupportTab() {
  const {
    handleSubmit,
		errors,
		isDirty,
    isValid,
		phoneRegister,
    emailRegister,
    fullNameRegister,
    messageRegister,
    getValues
  } = useHookForm({
    isFullName: true,
    isPhone: true,
    isEmail: true,
    isMessage: true,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = async (data) => {
    const payload = {
      ...data,
      phone_number: data.mobile_number,
      email_address: data.email,
    }
    delete payload.mobile_number;
    delete payload.email;
    const response = await supportTicket(payload)
    if (response.status === 201) {
      dispatch(setSnackbar({
        display: true,
        variant: 'success',
        message: t('my_profile.help_success_message')
      }))
      navigate('/');
    } else {
      dispatch(setSnackbar({
        display: true,
        variant: 'error',
        message: response.response.data.message
      }))
    }
  }

  return (
    <div className={styles['help-main']}>
      <div className={styles['help-email']}>
        <img src={emailIcon} className={styles['help-email-image']} alt='email us' />
        <p className={styles['help-email-text']}>
          <a href='emailto:seifsabry@kazbanakazbana.com'>
            {t('my_profile.email')}
            <span className={styles['help-email-email']}>seifsabry@kazbanakazbana.com</span>
          </a>
        </p>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)} noValidate className={styles['help-contact']}>
        <h3 className={styles['help-contact-header']}>{t('my_profile.contact_us')}</h3>
        <label className={styles['help-contact-label']}>
          {t('my_profile.fullname')}
          <FormInput
            name='full_name'
            placeholder={t('my_profile.fullname_placeholder')}
            maxLength={100}
            formRegister={fullNameRegister}
            error={errors['full_name']}
          />
        </label>
        <label className={styles['help-contact-label']}>
          {t('my_profile.phone')}
          <FormInput
            name='mobile_number'
            label={
              <>
                <img src={egyptIcon} alt='egypt flag' />
                <span>
                +20
                </span>
              </>
            }
            error={errors['mobile_number']}
            placeholder={t('my_profile.phone_placeholder')}
            maxLength={10}
            formRegister={phoneRegister}
          />
        </label>
        <label className={styles['help-contact-label']}>
          {t('my_profile.email_only')}
          <FormInput
            name='email'
            error={errors['email']}
            placeholder={t('my_profile.email')}
            formRegister={emailRegister}
          />
        </label>
        <label className={styles['help-contact-label']}>
          {t('my_profile.message')}
          <FormTextarea
            name='message'
            error={errors['message']}
            placeholder={t('my_profile.message_placeholder')}
            formRegister={messageRegister}
          />
        </label>
        <FormButton type={'submit'} text={t('my_profile.send_button')}/>
      </form>
    </div>
  )
}

export default HelpAndSupportTab;
