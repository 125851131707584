import logo from '../../assets/images/logo.png';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import FormInput from '../formInput';
import styles from './index.module.scss';
import Button from '../formButton';
import LanguageSwitcher from '../languageSwitcher';
import { useTranslation } from 'react-i18next';
import { useHookForm } from '../../hooks/hook-form';
import PasswordValidations from '../passwordValidations';

export function ForgetPasswordForm({ handleFormSubmit, children }) {
  const { t } = useTranslation();
  
  const {
    handleSubmit,
		errors,
		isDirty,
		isValid,
    phoneRegister,
    passwordRegister,
    confirmPasswordRegister,
    dirtyFields,
    getValues
  } = useHookForm({
    isPhone: true,
    isPassword: true,
    isConfirmPassword: true,
  });

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={styles['form']}>
      <img className={styles['form-logo']} src={logo} alt='logo' />
      <div className={styles['form-content']}>
        <h3 className={styles['form-content-title']}>{t('forget_password.title')}</h3>
        <p className={styles['form-content-body']}>{t('forget_password.description')}</p>
        <div className={styles['form-input-container']}>
          <FormInput
            name='mobile_number'
            label={
              <>
                <img src={egyptIcon} alt='egypt flag' />
                <span>
                +20
                </span>
              </>
            }
            error={errors['mobile_number']}
            placeholder={t('forget_password.phone_placeholder')}
            maxLength={10}
            formRegister={phoneRegister}
          />
          <FormInput
            name='password1'
            type='password'
            error={errors['password1']}
            placeholder={t('forget_password.password_placeholder')}
            formRegister={passwordRegister}
          />
          <PasswordValidations isDirty={dirtyFields['password1']} error={errors['password1']} value={getValues('password1')}/>
          <FormInput
            name='password2'
            type='password'
            error={errors['password2']}
            placeholder={t('forget_password.confirm_password_placeholder')}
            formRegister={confirmPasswordRegister}
          />
        </div>
        <div className={styles['form-actions']}>
          <Button text={t('forget_password.change_password_button')} disabled={!isValid || !isDirty} />
          {children}
          <div className={styles['language-switcher-container']}>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ForgetPasswordForm;