import { getCookie, setCookie } from '../../helpers/cookiesStorage';
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		token: getCookie('token', ''),
		user: getCookie('user', ''),
		profile: getCookie('profile', ''),
	},
	reducers: {
		setUser: (state, action) => {
			setCookie('token', action.payload.token);
			setCookie('user', action.payload.user);
			setCookie('profile', action.payload.profile);
			return action.payload;
		},
		setProfile: (state, action) => {
			setCookie('profile', action.payload);
			state.profile = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setUser, setProfile } = userSlice.actions;

export default userSlice.reducer;
