import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const referFriend = () => {
	return axios
		.get('/api/ecommerce/referral-code/', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const createSupportTicket = (payload) => {
	return axios
		.post('/api/content/support-ticket/', payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getTerms = () => {
	return axios
		.get('/api/content/terms-and-conditions')
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getCountries = () => {
	return axios
		.get('/api/content/countries', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const shipWheel = (id, payload) => {
	return axios
		.post(`/api/wheel/${id}/ship`, payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getFavouriteProducts = () => {
	return axios
		.get('/api/account/favourite-products', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const addToFavourites = (slug) => {
	return axios
		.get(`/api/ecommerce/products/${slug}/add-favourite`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const removeFromFavourites = (slug) => {
	return axios
		.get(`/api/ecommerce/products/${slug}/remove-favourite`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getNotifications = () => {
	return axios
		.get('/api/content/notifications/', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const readNotification = (id) => {
	return axios
		.post(`/api/content/notification/${id}/read`, undefined, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const readNoAction = () => {
	return axios
		.get(`/api/content/notifications/read-no-action`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const deleteNotification = (id) => {
	return axios
		.delete(`/api/content/notification/${id}`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const supportTicket = (payload) => {
	return axios
		.post('/api/content/support-ticket', payload)
		.then((response) => response)
		.catch((err) => err?.response);
};
