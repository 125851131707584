import { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import { useState } from 'react';
import { referFriend } from '../../services/sharedService';
import ProgressSteps from '../progressSteps';
import referImage from '../../assets/images/refer-image.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

export default function ReferFriendTab() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [textToCopy, setTextToCopy] = useState('');
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const counter = useRef(0);

	const getRefferalCode = async () => {
		setLoading(true);
		const response = await referFriend();
		if (response.status === 200) {
			setTextToCopy(`https://kazbanakazbana.com/signup?referral_code=${response.data.code}`);
			setData(response.data);
		} else {
			// handle error
		}
		setLoading(false);
	};

	const onCopyText = () => {
		dispatch(
			setSnackbar({
				display: true,
				variant: 'success',
				message: t('code_copied'),
			})
		);
	};

	useEffect(() => {
		if (counter.current === 0) {
			getRefferalCode();
		}
		counter.current++;
	}, []);

	if (data && !loading) {
		return (
			<div className={styles['refer']}>
				<ProgressSteps minStep={data.min_refferal_amount} maxStep={data.max_refferal_amount} current={data.current_refferal_amount} />
				<div className={styles['refer-content']}>
					<img className={styles['refer-content-image']} src={referImage} alt='' />
					<p className={styles['refer-content-title']}>
						{t('my_profile.invite')}{' '}
						<span>
							{data.level * 5} {t('currency')}
						</span>
					</p>
					<p className={styles['refer-content-desc']}>
						{t('my_profile.give')} {data.level * 5} {t('currency')} {t('my_profile.to_balance')}
					</p>
				</div>
				<div className={styles['refer-code']}>
					<p className={styles['refer-code-title']}>{t('my_profile.referral_link')}</p>
					<CopyToClipboard text={textToCopy} onCopy={onCopyText}>
						<p className={styles['refer-code-code']}>
							<span>https://kazbanakazbana.com/signup?referral_code={data.code}</span>
							<button className={styles['refer-code-copy']}>{t('my_profile.copy_code')}</button>
						</p>
					</CopyToClipboard>
				</div>
				<p className={styles['terms']}>{t('my_profile.terms_applied')}</p>
			</div>
		);
	} else {
		return <Loader />;
	}
}
