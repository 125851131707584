import styles from './index.module.scss';
import eye from '../../assets/icons/eye.svg';
import { useState } from 'react';

export function FormInput(props) {
  const {
    formRegister,
    icon,
    name,
    placeholder,
    label,
    value,
    maxLength,
    type,
    error,
    inputMode,
    disabled,
  } = props;
  
  const [showPassword, setShowPassword] = useState(()=>type);

  const handleShowPassword = () => {
    setShowPassword(prev => prev === 'password' ? 'text' : 'password');
  }
  return (
    <div className={styles['container']}>
      <div
        className={[
          styles['input-container'],
          error ? styles['input-container-error'] : ''
        ].join(' ')}
      >
        {label && 
        <label htmlFor={name} className={'input-label'}>
          {label}
        </label>
        }
        <input
          id={name}
          name={name}
          type={showPassword}
          {...formRegister}
          placeholder={placeholder ? placeholder : ''}
          maxLength={maxLength}
          autoComplete="off"
          value={value}
          inputMode={inputMode}
          disabled={disabled}
        />
        {icon && icon}
        {type === 'password' && <img className={styles['eye']} src={eye} onClick={handleShowPassword} alt='show password' />}
      </div>
      {error && name !== 'password1' && <p className={styles['form-error']}>{error?.message}</p>}
    </div>
  );
}

export default FormInput;