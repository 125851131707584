import styles from './index.module.scss';
import logo from '../../assets/images/logo.png';
import Button from '../formButton';
import LanguageSwitcher from '../languageSwitcher';
import OTP from '../otp';
import { useEffect, useRef, useState } from 'react';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function OTPStep({ phone, minutes = 1, handleOTPSubmit, setOTP, handleResendOTP, children }) {
  const [tick, setTick] = useState(minutes * 60);
  const [timerId, setTimerId] = useState();
  const [showResend, setShowResend] = useState(false);
  const [resetOTP, setResetOTP] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasStarted = useRef(false);
  
  useEffect(() => {
    if (!hasStarted?.current) {
      hasStarted.current = true;
      const timer = setInterval(() => {
        setTick(prev => prev - 1);
      }, 1000)
      setTimerId(timer);
    }
    return () => {
      clearInterval(timerId);
    }
  }, []);

  useEffect(() => {
    if (tick === 0) {
      clearInterval(timerId);
      setShowResend(true);
    }
  },[tick])

  const getTimer = () => {
    const minutes = Math.floor(tick / 60) % 60;
    const seconds = tick % 60;
    return `${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`
  };
  const handleOTPComplete = (otp) => {
    setDisableButton(false);
    setOTP(otp);
  };
  const handleOTPIncomplete = () => {
    setDisableButton(true);
    setOTP('')
  };

  const resendOTP = async () => {
    const response = await handleResendOTP();
    if (response.status === 200) {
      dispatch(setSnackbar({
        display: true,
        variant: 'success',
        message: response.data.message
      }))
      setResetOTP(true);
      setShowResend(false);
      setTick(minutes * 60);
      setOTP('');
      const timer = setInterval(() => {
        setTick(prev => prev - 1);
      }, 1000);
      setTimerId(timer);
      setDisableButton(true);
    } else {
      dispatch(setSnackbar({
        display: true,
        variant: 'error',
        message: response.response.data.message
      }))
    }
  }

  const handleHomeNavigation = () => {
    navigate('/')
  }

  return (
    <section className={styles['otp-step']}>
      <img onClick={handleHomeNavigation} className={styles['otp-step-logo']} src={logo} alt='logo' />
      <div className={styles['otp-step-content']}>
        <h3 className={styles['otp-step-content-title']}>{t('otp.title')}</h3>
        <p className={styles['otp-step-content-body']}>{t('otp.description')} {phone}</p>
        <div className={styles['otp-step-input-container']}>
          <OTP
            length={4}
            resetOTP={resetOTP}
            onComplete={handleOTPComplete}
            inComplete={handleOTPIncomplete}
          />
          {!showResend && 
            <p className={styles['otp-step-resend-text']}>
              {t('otp.will_expire')} <span>{getTimer()}</span>
            </p>
          }
          {showResend &&
            <p className={styles['otp-step-resend-text']}>
              {t('otp.did_expire')}
              <button
                className={styles['otp-step-resend']}
                onClick={resendOTP}
              >
                {t('otp.resend')}
              </button>
            </p>
          }
        </div>
        <div className={styles['otp-step-actions']}>
          <Button text={t('otp.continue')} disabled={disableButton} handleSubmit={handleOTPSubmit} />
          {children}
          <div className={styles['language-switcher-container']}>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </section>
  )
}

export default OTPStep;