import styles from './index.module.scss';
import avatar from '../../assets/icons/avatar.svg';
import badge from '../../assets/icons/badge.svg';
import camera from '../../assets/icons/camera.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import DiscountIcon from './icons/discountIcon';
import HelpAndSupportIcon from './icons/helpAndSupportIcon';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/userSlice';
import { logout } from '../../services/authSharedService';
import { useState } from 'react';
import ImageUpload from '../imageUpload';
import MyWheelsIcon from './icons/myWheelsIcon';
import FavouritesIcon from './icons/favouritesIcon';
import OtherTransactionsIcon from './icons/otherTransactionsIcon';
import LogoutModal from '../logoutModal';
import { setLoading } from '../../store/slices/loadingSlice';
import ProfileData from '../profileData';
import SettingsIcon from './icons/settingsIcon';
import ReferIcon from './icons/referIcon';
import closeIcon from '../..//assets/icons/close-x.svg';
import { useTranslation } from 'react-i18next';

export function Profile({profile, open, setOpen}) {
  const [showModal, setShowModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { tab } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabs = [
  {
    id: 0,
    icon: <OtherTransactionsIcon color={ tab !== 'other-transactions' ? '#3C3C43': null}/>,
    label: t('my_profile.other-transactions'),
    tab_name: 'other-transactions',
    redirection: 'other-transactions'
  },
  {
    id: 1,
    icon: <FavouritesIcon color={ tab !== 'favourites' ? '#3C3C43': null}/>,
    label: t('my_profile.favourites'),
    tab_name: 'favourites',
    redirection: 'favourites'
  },
  {
    id: 2,
    icon: <MyWheelsIcon color={ tab !== 'my-wheels' ? '#3C3C43': null}/>,
    label: t('my_profile.my-wheels'),
    tab_name: 'my-wheels',
    redirection: 'my-wheels'
  },
  {
    id: 3,
    icon: <DiscountIcon color={tab !== 'discount-store' ? '#3C3C43': null} />,
    label: t('my_profile.discount-store'),
    tab_name: 'discount-store',
    redirection: 'discount-store'
  },
  {
    id: 4,
    icon: <SettingsIcon color={tab !== 'settings' ? '#3C3C43': null}/>,
    label: t('my_profile.settings'),
    tab_name: 'settings',
    redirection: 'settings',
  },
  {
    id: 5,
    icon: <HelpAndSupportIcon color={tab !== 'help-and-support' ? '#3C3C43': null}/>,
    label: t('my_profile.help-and-support'),
    tab_name: 'help-and-support',
    redirection: 'help-and-support',
  },
  {
    id: 6,
    icon: <ReferIcon color={tab !== 'refer-friend' ? '#3C3C43': null}/>,
    label: t('my_profile.refer-friend'),
    tab_name: 'refer-friend',
    redirection: 'refer-friend',
  },
  {
    id: 7,
    icon: logoutIcon,
    label: t('my_profile.logout'),
    isLogout: true,
    tab_name: 'logout',
  },
]

  const handleListNavigation = (listTab) => {
    if (!listTab.isLogout) {
      navigate(listTab.redirection)
      setOpen(false);
    } else {
      handleShowLogoutModal();
    }
  }
  const handleShowLogoutModal = () => {
    setShowLogoutModal(true);
  }
  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  }
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleDataModalClose = () => {
    setShowDataModal(false);
  };
  const handleShowDataModal = () => {
    setShowDataModal(true);
  };
  const handleLogout = async () => {
    const response = await logout();
    if (response.status === 200) {
      dispatch(setLoading(true));
      dispatch(setUser({ user: null, profile: null, token: '' }));
      const id = setTimeout(() => {
        navigate('/');
        dispatch(setLoading(false));
        clearTimeout(id)
      },1000)
    }
  }

  if (profile) {
    return (
      <div className={[styles['profile'],open && styles['open']].join(' ')}>
        {showModal &&
          <ImageUpload setShowModal={setShowModal} handleModalClose={handleModalClose}/>
        }
        {showLogoutModal &&
          <LogoutModal handleLogout={handleLogout} handleModalClose={handleCloseLogoutModal} />
        }
        {showDataModal &&
          <ProfileData setShowModal={setShowDataModal} handleModalClose={handleDataModalClose}/>
        }
        <div className={styles['profile-data']}>
          {open && 
            <img src={closeIcon} onClick={() => setOpen(false)} className={styles['menu-close']} alt='' />
          }
          <div onClick={handleShowModal} className={styles['profile-image-container']}>
            <img className={styles['profile-image']} src={profile.profile_picture ? profile.profile_picture : avatar} alt='profile' />
            <span className={styles['profile-image-upload']}>
              <img className={styles['profile-image-upload-icon']} src={camera} alt='upload' />
            </span>
          </div>
          <p className={styles['profile-name']}>{profile.full_name}</p>
          <p onClick={handleShowDataModal} className={styles['profile-manage-data']}>{t('my_profile.manage')}</p>
          <p className={styles['profile-level']}>
            <img src={badge} alt='badge' />
            {profile.level}
          </p>
        </div>
        <div className={styles['profile-details']}>
          <div onClick={() => { navigate('available-balance'); setOpen(false); }} className={styles['profile-details-card']}>
            <div className={styles['profile-details-card-content']}>
              <p className={styles['profile-details-card-number']}>
              {Number.parseInt(profile.available_balance)} {t('currency')}
              </p>
              <span className={styles['profile-details-card-text']}>{t('my_profile.available-balance')}</span>
            </div>
          </div>
          <div onClick={() => { navigate('discount-won'); setOpen(false); }} className={styles['profile-details-card']}>
            <div className={styles['profile-details-card-content']}>
              <p className={styles['profile-details-card-number']}>
              {Number.parseInt(profile.discount_won)} {t('currency')}
              </p>
              <span className={styles['profile-details-card-text']}>{t('my_profile.discount-won')}</span>
            </div>
          </div>
          <div onClick={() => { navigate('big-wins');setOpen(false); }} className={styles['profile-details-card']}>
            <div className={styles['profile-details-card-content']}>
              <p className={styles['profile-details-card-number']}>
              {profile.big_wins}
              </p>
              <span className={styles['profile-details-card-text']}>{t('my_profile.big-wins')}</span>
            </div>
          </div>
        </div>
        <ul className={styles['profile-tabs']}>
          {tabs.map(listTab => 
            <li key={listTab.id} onClick={() => handleListNavigation(listTab)} className={[styles['profile-tab'],listTab.tab_name === tab ? styles['profile-tab-selected'] : ''].join(' ')}>
              {listTab.tab_name === 'logout' ?
                <img className={styles['profile-tab-image']} src={listTab.icon} alt={listTab.label} /> :
                listTab.icon}
              <p className={styles['profile-tab-text']}>{listTab.label}</p>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default Profile;