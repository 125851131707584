import { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import LiveWheelAnimation from '../../components/liveWheelAnimation';
import Wheel from '../../components/wheel';
import { useNavigate, useParams } from 'react-router';
import { getSingleWheel, spinWheel } from '../../services/liveWheelService';
import whiteLogo from '../../assets/images/navbarEnLogoWhite.png';
import whiteLogoAr from '../../assets/images/navbarArLogoWhite.png';
import AutoSlider from '../../components/slider/autoSlider';
import Ticket from '../../components/ticket';
import { SwiperSlide } from 'swiper/react';
import QualifiedModal from '../../components/qualifiedModal';
import UnQualifiedModal from '../../components/unQualifiedModal';
import WinnerModal from '../../components/winnerModal';
import countDown from '../../assets/audios/count-down.mp3';
import wheelSpin from '../../assets/audios/spin.mp3';
import inProgress from '../../assets/audios/in-progress.mp3';
import qualified from '../../assets/audios/qualified.wav';
import lose from '../../assets/audios/lose.mp3';
import win from '../../assets/audios/Win.mp3';
import { useTranslation } from 'react-i18next';

export function LiveWheel() {
	const [showLiveAnimation, setShowLiveAnimation] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [willStart, setWillStart] = useState(true);
	const [ended, setEnded] = useState(false);
	const [startWheel, setStartWheel] = useState(false);
	const [wheelData, setWheelData] = useState(null);
	const [currentSpin, setCurrentSpin] = useState(null);
	const [currentIteration, setCurrentIteration] = useState(1);
	const [slotsNumber, setSlotsNumber] = useState(null);
	const [currentLabels, setCurrentLabels] = useState(null);
	const [nextLabels, setNextLabels] = useState(null);
	const [winnerSlot, setWinnerSlot] = useState(null);
	const [lastIterationRange, setLastIterationRange] = useState(null);
	const renderCount = useRef(1);
	const { t, i18n } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const liveAnimationAudioRef = useRef(null);
	const wheelSpinAudioRef = useRef(null);
	const inProgressAudioRef = useRef(null);

	const handleFinishCounter = () => {
		setShowLiveAnimation(false);
		const id = setTimeout(() => {
			setStartWheel(true);
			setWillStart(false);
			clearTimeout(id);
		}, 1000);
	};
	const handleFinishWheelSpin = async () => {
		setStartWheel(false);
		setEnded(true);
		setLastIterationRange(`${currentSpin?.win_slot_range_start}-${currentSpin?.win_slot_range_end}`);
		const id = setTimeout(() => {
			setShowModal(true);
			clearTimeout(id);
		}, 3000);
	};
	const handleWheelSpin = async () => {
		const response = await spinWheel(params.id);
		if (response.status === 200) {
			if (response.data.data.iteration_number > 1) {
				if (response.data.data.next_iteration_ranges) {
					setLastIterationRange(
						`${response.data.data.slot_ranges.slots[0][0]}-${
							response.data.data.slot_ranges.slots[response.data.data.slot_ranges.slots.length - 1][1]
						}`
					);
				} else {
					setLastIterationRange(
						`${response.data.data.slot_ranges.slots[0][0]}-${
							response.data.data.slot_ranges.slots[response.data.data.slot_ranges.slots.length - 1][0]
						}`
					);
				}
			}
			setCurrentSpin(response.data.data);
			if (response.data.data.slot_ranges.slots[response.data.data.slot_ranges.slots.length - 1].length > 1) {
				const slots =
					response.data.data.slot_ranges.slots[response.data.data.slot_ranges.slots.length - 1][1] - response.data.data.slot_ranges.slots[0][0] + 1;
				setSlotsNumber(slots);
			} else {
				const slots =
					response.data.data.slot_ranges.slots[response.data.data.slot_ranges.slots.length - 1][0] - response.data.data.slot_ranges.slots[0][0] + 1;
				setSlotsNumber(slots);
			}
			if (response.data.data.slot_ranges.slots.length < 8) {
				const labels = response.data.data.slot_ranges.slots.map((label) => {
					if (label.length > 1) {
						if (label[0] === label[1]) return label[0];
						return label.join(' - ');
					} else {
						return label[0];
					}
				});
				const reversed = labels?.reverse();
				for (let i = reversed.length; i < 8; i++) {
					reversed.unshift('');
				}
				setCurrentLabels(reversed);
			} else {
				const labels = response.data.data.slot_ranges.slots.map((label) => {
					if (label.length > 1) {
						if (label[0] === label[1]) return label[0];
						return label.join(' - ');
					} else {
						return label[0];
					}
				});
				setCurrentLabels(labels?.reverse());
			}
			if (response.data.data.next_iteration_ranges?.slots < 8) {
				const nextSpinLabels = response.data.data.next_iteration_ranges?.slots.map((label) => {
					if (label.length > 1) {
						if (label[0] === label[1]) return label[0];
						return label.join(' - ');
					} else {
						return label[0];
					}
				});
				const reversed = nextSpinLabels?.reverse();
				for (let i = reversed.length; i < 8; i++) {
					reversed.unshift('');
				}
				setNextLabels(reversed);
			} else {
				const nextSpinLabels = response.data.data.next_iteration_ranges?.slots.map((label) => {
					if (label.length > 1) {
						if (label[0] === label[1]) return label[0];
						return label.join(' - ');
					} else {
						return label[0];
					}
				});
				setNextLabels(nextSpinLabels?.reverse());
			}
			setWinnerSlot(response.data.data.win_slot);
		}
	};
	const getWheelData = async () => {
		const response = await getSingleWheel(params.id);
		if (response.status === 200) {
			setWheelData(response.data);
			setCurrentIteration(response.data.iterations.length);
			setSlotsNumber(response.data.product.no_of_tickets);
			if (response.data.iterations[0].slot_ranges.slots.length < 8) {
				const labels = response.data.iterations[0].slot_ranges.slots.map((label) => label.join(' - '));
				labels.push('');
				setCurrentLabels(labels);
			} else {
				const labels = response.data.iterations[0].slot_ranges.slots.map((label) => label.join(' - '));
				setCurrentLabels(labels);
			}
			handleWheelSpin();
		} else {
			// handle error
		}
	};
	const onQualifiedModalSubmit = async () => {
		setShowModal(false);
		setCurrentIteration((prev) => prev + 1);
		setCurrentLabels(nextLabels);
		setNextLabels(null);
		setEnded(false);
		setWillStart(true);
		handleWheelSpin();
		const id = setTimeout(() => {
			setStartWheel(true);
			setWillStart(false);
			clearTimeout(id);
		}, 3000);
	};
	const onUnQualifiedModalSubmit = () => {
		setShowModal(false);
		setCurrentIteration((prev) => prev + 1);
		setCurrentLabels(nextLabels);
		setNextLabels(null);
		setEnded(false);
		setWillStart(true);
		handleWheelSpin();
		const id = setTimeout(() => {
			setStartWheel(true);
			setWillStart(false);
			clearTimeout(id);
		}, 3000);
	};
	const onUnQualifiedModalDecline = () => {
		navigate('/my-profile/discount-won');
	};
	const onWinnerModalSubmit = () => {
		navigate('/');
	};
	const getIterationCount = () => {
		switch (currentIteration) {
			case 1:
				return i18n.language === 'en-US' ? `1st` : '1';
			case 2:
				return i18n.language === 'en-US' ? `2nd` : '2';
			case 3:
				return i18n.language === 'en-US' ? `3rd` : '3';
			default:
				return i18n.language === 'en-US' ? `${currentIteration}th` : currentIteration;
		}
	};

	useEffect(() => {
		if (renderCount?.current === 1 && params.id) {
			getWheelData();
			renderCount.current++;
		}
	}, []);
	useEffect(() => {
		if (showLiveAnimation && liveAnimationAudioRef.current) {
			liveAnimationAudioRef.current.play();
		}
	}, [showLiveAnimation]);
	useEffect(() => {
		if (startWheel && wheelSpinAudioRef.current) {
			wheelSpinAudioRef.current.play();
		}
	}, [startWheel]);
	useEffect(() => {
		if (
			(currentSpin && !startWheel && willStart && !ended && currentIteration === 1) ||
			(currentSpin && !startWheel && willStart && !ended && currentIteration > 1)
		) {
			inProgressAudioRef.current.play();
		}
	}, [currentIteration, currentSpin, ended, startWheel, willStart]);

	return (
		<div className={styles['live-wheel']}>
			{showLiveAnimation && <LiveWheelAnimation handleFinishCounter={handleFinishCounter} />}
			{showLiveAnimation && (
				<audio ref={liveAnimationAudioRef}>
					<source src={countDown} type='audio/mpeg' />
				</audio>
			)}
			{startWheel && (
				<audio ref={wheelSpinAudioRef}>
					<source src={wheelSpin} type='audio/wav' />
				</audio>
			)}
			<section className={styles['live-wheel-details']}>
				<div className={styles['live-wheel-logo']}>
					<img src={i18n.language === 'en-US' ? whiteLogo : whiteLogoAr} className={styles['live-wheel-details-logo']} alt='kasbana logo' />
				</div>
				<div className={styles['live-wheel-details-content']}>
					<div className={styles['live-wheel-details-content-section']}>
						<h3 className={styles['live-wheel-details-content-title']}>{t('live_wheel.live_wheel')}</h3>
						<p className={styles['live-wheel-details-content-text']}>
							<span className={styles['live-dot']}></span>
							{t('live_wheel.live_now')}
						</p>
					</div>
					<div className={styles['live-wheel-details-content-section']}>
						<div className={styles['live-wheel-details-product']}>
							<div className={styles['live-wheel-details-product-content']}>
								<p className={styles['live-wheel-details-product-title']}>{t('live_wheel.product_name')}</p>
								<h4 className={styles['live-wheel-details-product-name']}>{wheelData?.product.name}</h4>
								<p className={styles['live-wheel-details-product-wheel']}>
									{t('live_wheel.wheel_number')} {wheelData?.product.wheel_number}
								</p>
							</div>
							<img className={styles['live-wheel-details-product-image']} src={wheelData?.product.thumbnail} alt={wheelData?.product.name} />
						</div>
					</div>
					<div className={styles['live-wheel-details-content-section']}>
						<p className={styles['live-wheel-details-tickets-title']}>{t('live_wheel.your_tickets')}</p>
						<div className={styles['tickets-container']}>
							{wheelData?.ticket_number.slots.length > 3 ? (
								<AutoSlider normal={true} number_of_tickets={wheelData?.ticket_number.slots.length}>
									{wheelData?.ticket_number.slots.map((number) => (
										<SwiperSlide key={number}>
											<Ticket selectedTickets={wheelData?.ticket_number.slots} number={number} />
										</SwiperSlide>
									))}
								</AutoSlider>
							) : (
								<div className={styles['tickets-container-small']}>
									{wheelData?.ticket_number.slots.map((number) => (
										<Ticket key={number} selectedTickets={wheelData?.ticket_number.slots} number={number} />
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			<section className={styles['wheel']}>
				<p className={styles['wheel-title']}>
					{currentSpin && startWheel && !willStart && !ended && currentIteration === 1 && (
						<>
							{i18n.language === 'en-US'
								? `${getIterationCount()} ${t('live_wheel.wheel_iteration')}`
								: `${t('live_wheel.wheel_iteration')} ${getIterationCount()}`}
							<span className={styles['wheel-title-slots']}>
								{slotsNumber} {t('live_wheel.slots')}
							</span>
						</>
					)}
					{currentSpin && startWheel && !willStart && !ended && currentIteration > 1 && (
						<>
							{i18n.language === 'en-US'
								? `${getIterationCount()} ${t('live_wheel.wheel_iteration')}`
								: `${t('live_wheel.wheel_iteration')} ${getIterationCount()}`}
							<span className={styles['wheel-title-slots']}>{lastIterationRange}</span>
						</>
					)}
					{currentSpin && !startWheel && willStart && !ended && currentIteration === 1 && (
						<>
							{i18n.language === 'en-US'
								? `${getIterationCount()} ${t('live_wheel.wheel_iteration_will_start')}`
								: `${t('live_wheel.wheel_iteration_will_start')} ${getIterationCount()}`}
							<span className={styles['wheel-title-slots']}>
								{slotsNumber} {t('live_wheel.slots')}
							</span>
							<audio ref={inProgressAudioRef}>
								<source src={inProgress} type='audio/mpeg' />
							</audio>
						</>
					)}
					{currentSpin && !startWheel && willStart && !ended && currentIteration > 1 && (
						<>
							{i18n.language === 'en-US'
								? `${getIterationCount()} ${t('live_wheel.wheel_iteration_will_start')}`
								: `${t('live_wheel.wheel_iteration_will_start')} ${getIterationCount()}`}
							<span className={styles['wheel-title-slots']}>{lastIterationRange}</span>
							<audio ref={inProgressAudioRef}>
								<source src={inProgress} type='audio/mpeg' />
							</audio>
						</>
					)}
					{currentSpin && currentSpin.status === 'win' && ended && (
						<>
							{t('live_wheel.ticket_winner')}
							<span className={styles['wheel-title-slots']}>{currentSpin?.win_slot_range_start}</span>
						</>
					)}
					{currentSpin && currentSpin.status !== 'win' && ended && !currentSpin.next_iteration_ranges && (
						<>
							{t('live_wheel.ticket_winner')}
							<span className={styles['wheel-title-slots']}>{currentSpin?.winner_ticket}</span>
						</>
					)}
					{currentSpin && currentSpin.status !== 'win' && ended && currentSpin.next_iteration_ranges && (
						<>
							{i18n.language === 'en-US'
								? `${getIterationCount()} ${t('live_wheel.wheel_iteration_progress')}`
								: `${t('live_wheel.wheel_iteration_progress')} ${getIterationCount()}`}
							<span className={styles['wheel-title-slots']}>
								{currentSpin?.win_slot_range_start === currentSpin?.win_slot_range_end
									? `${currentSpin?.win_slot_range_start} ${t('live_wheel.slot')}`
									: `${currentSpin?.win_slot_range_start}-${currentSpin?.win_slot_range_end}`}
							</span>
						</>
					)}
				</p>
				<div className={styles['wheel-wheel']}>
					<Wheel winnerSlot={winnerSlot} currentLabels={currentLabels} handleFinishWheelSpin={handleFinishWheelSpin} startWheel={startWheel} />
				</div>
			</section>
			{showModal && currentSpin.status === 'passed' && (
				<QualifiedModal
					onModalSubmit={onQualifiedModalSubmit}
					tickets_remaining={wheelData?.ticket_number.slots.filter(
						(num) => num <= currentSpin.win_slot_range_end && num >= currentSpin.win_slot_range_start
					)}
					range={`${currentSpin.win_slot_range_start}-${currentSpin.win_slot_range_end}`}
				/>
			)}
			{showModal && currentSpin.status === 'passed' && (
				<audio autoPlay>
					<source src={qualified} type='audio/wav' />
				</audio>
			)}
			{showModal && (currentSpin.status === 'failed' || currentSpin.status === 'end') && (
				<UnQualifiedModal
					price={wheelData?.ticket_number.slots.length * wheelData?.product.price_per_product}
					isFinished={currentSpin.status === 'end'}
					onModalSubmit={onUnQualifiedModalSubmit}
					onModalDecline={onUnQualifiedModalDecline}
				/>
			)}
			{showModal && (currentSpin.status === 'failed' || currentSpin.status === 'end') && (
				<audio autoPlay>
					<source src={lose} type='audio/mpeg' />
				</audio>
			)}
			{showModal && currentSpin.status === 'win' && <WinnerModal name={wheelData?.product.name} onModalSubmit={onWinnerModalSubmit} />}
			{showModal && currentSpin.status === 'win' && (
				<audio autoPlay>
					<source src={win} type='audio/mpeg' />
				</audio>
			)}
		</div>
	);
}

export default LiveWheel;
