import styles from './index.module.scss';
import Button from '../formButton';
import qualified from '../../assets/images/qualified.gif';
import Confetti from 'react-confetti';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

const colors = [
  '#a51d5d',
  '#f49c26',
  '#b46926',
  '#ed7443',
  '#b25237',
  '#e95c54',
  '#ab324a',
  '#e13a6c',
]

export function QualifiedModal({ onModalSubmit, tickets_remaining, range }) {
  const { t } = useTranslation();
  
  return (
    <>
      <Confetti width={window.innerWidth} colors={colors} initialVelocityY={20} tweenDuration={1000} numberOfPieces={100}/>
      <div className={styles['modal']}>
        <div className={styles['modal-container']}>
          <img src={qualified} alt='qualified' className={styles['modal-image']} />
          <div className={styles['modal-content']}>
            <p className={styles['modal-text']}>{t('live_wheel.still_in')} {range} {t('live_wheel.qualified')}</p>
            {tickets_remaining.length > 0 && 
              <p className={styles['modal-text-secondary']}>{tickets_remaining.length === 1 ? t('ticket') : t('tickets')} {t('live_wheel.number')} {tickets_remaining.join(' & ')} {t('live_wheel.can_win')}</p>
            }
            <Button handleSubmit={onModalSubmit} text={t('live_wheel.continue')} type={'button'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default QualifiedModal;