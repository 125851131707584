import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFavourites } from '../../store/slices/favouritesSlice';
import { getFavouriteProducts } from '../../services/sharedService';
import HottestWheelCard from '../hottestWheelCard';
import favouritesEmpty from '../../assets/images/favourites-empty.png';
import Loader from '../loader';

export default function FavouritesTab() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const favourites = useSelector(state => state.favourites);

  const handleGetFavourites = async () => {
    setLoading(true);
    const response = await getFavouriteProducts();
    if (response.status === 200) {
      dispatch(setFavourites(response.data));
    } else {
      // handle error
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetFavourites();
  }, []);
  
  if (favourites && !loading) {
    return (
      <div className={styles['favourites']}>
        <div className={styles['favourites-container']}>
          {favourites.length > 0 ? favourites.map(product =>
            <div className={styles['favourites-product']} key={product.id}>
              <HottestWheelCard product={product} />
            </div>
          ) : (
            <img className={styles['favourites-empty']} src={favouritesEmpty} alt='' />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <Loader/>
    )
  }
}
