import styles from './index.module.scss';
import Button from '../formButton';
import unQualified from '../../assets/images/unqualified.gif';
import { useTranslation } from 'react-i18next';

export function UnQualifiedModal({onModalSubmit, onModalDecline, isFinished, price}) {
  const { t } = useTranslation();

  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <img src={unQualified} alt='unqualified' className={styles['modal-image']} />
        <div className={styles['modal-content']}>
          <p className={styles['modal-text']}>{t('live_wheel.you_are_out')} {price}{t('currency')} {t('live_wheel.as_discount')}</p>
          <div className={styles['modal-content-actions']}>
            {!isFinished && 
              <Button handleSubmit={onModalSubmit} text={t('live_wheel.continue_watching')} type={'button'} />
            }
            <Button handleSubmit={onModalDecline} text={t('live_wheel.check_discount')} variant={!isFinished && 'outline'} type={'button'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnQualifiedModal;