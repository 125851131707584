import { useEffect, useRef } from 'react';
import styles from './index.module.scss';

export default function ProgressBar({progress}) {
  const progressRef = useRef(null);

  const handleProgress = (number) => {
    if (progressRef.current) {
      progressRef.current.style.setProperty('--progress', number + '%');
    }
  }

  useEffect(() => {
    handleProgress(progress);
  },[progress])

  return <div ref={progressRef} className={styles['progressbar']} aria-valuenow={progress}></div>
}
