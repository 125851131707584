import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const getOrderDetails = (orderId) => {
	return axios
		.get(`/api/ecommerce/order/${orderId}`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};
