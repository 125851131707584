import styles from './index.module.scss';
import { useState } from 'react';
import Button from '../formButton';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function BigWinCard({ bigWin }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const date = new Date(bigWin.updated_at);
  
  const handleShowModal = () => {
    setShowModal(true);  
  }
  const handleDeliver = () => {
    navigate('/my-profile/deliver-to-you',{ state: {bigWin}});
  }

  const getMonths = (month) => {
    switch (month) {
      case 0:
        return t('january');
      case 1:
        return t('february');
      case 2:
        return t('march');
      case 3:
        return t('april');
      case 4:
        return t('may');
      case 5:
        return t('june')
      case 6:
        return t('july');
      case 7:
        return t('august');
      case 8:
        return t('september');
      case 9:
        return t('october');
      case 10:
        return t('november');
      case 11:
        return t('december');
      default:
        return ''
    }
  }
  return (
    <div className={styles['card']} onClick={handleShowModal}>
      <div className={styles['card-container']}>
        <img className={styles['card-image']} src={bigWin.product.thumbnail} alt={bigWin.product.name} />
        <div className={styles['card-product']}>
          <p className={styles['card-wheel']}>{t('my_profile.wheel_number')} <span>{bigWin.product.wheel_number}</span></p>
          <h3 className={styles['card-product-title']}>{bigWin.product.name}</h3>
          <p className={styles['card-product-date']}>{`${date.getDate()} ${getMonths(date.getMonth())}, ${date.getHours() > 12 ? (date.getHours() - 12).toLocaleString('en-US',{minimumIntegerDigits:2}) : date.getHours().toLocaleString('en-US',{minimumIntegerDigits:2})}:${date.getMinutes().toLocaleString('en-US',{minimumIntegerDigits: 2})} ${date.getHours() >= 12 ? t('pm') : t('am')}`}</p>
          <p className={styles['card-winner']}>{t('ticket')} <span>{bigWin.winner_ticket}</span></p>
        </div>
      </div>
      <Button handleSubmit={handleDeliver} disabled={bigWin.delivery_needed} variant={'outline'} text={t('my_profile.deliver_button')} type={'button'}/>
    </div>
  )
}
