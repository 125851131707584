import logo from '../../assets/images/logo.png';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import FormInput from '../formInput';
import styles from './index.module.scss';
import Button from '../formButton';
import LanguageSwitcher from '../languageSwitcher';
import { useTranslation } from 'react-i18next';
import { useHookForm } from '../../hooks/hook-form';
import { useNavigate } from 'react-router';
import PasswordValidations from '../passwordValidations';

export function LoginForm({ handleFormSubmit, handleForgetPassword }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
		errors,
		isDirty,
    isValid,
		phoneRegister,
    requiredPasswordRegister,
    getValues
  } = useHookForm({
    isPhone: true,
    isRequiredPassword: true,
  });

  const handleHomeNavigation = () => {
    navigate('/');
  }

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={styles['form']}>
      <img onClick={handleHomeNavigation} className={styles['form-logo']} src={logo} alt='logo' />
      <div className={styles['form-content']}>
        <h3 className={styles['form-content-title']}>{t('login.title')}</h3>
        <p className={styles['form-content-body']}>{t('login.description')}</p>
        <div className={styles['form-input-container']}>
          <FormInput
            name='mobile_number'
            label={
              <>
                <img src={egyptIcon} alt='egypt flag' />
                <span>
                +20
                </span>
              </>
            }
            error={errors['mobile_number']}
            placeholder={t('login.phone_placeholder')}
            maxLength={10}
            formRegister={phoneRegister}
          />
          <FormInput
            name='password1'
            type='password'
            error={errors['password1']}
            placeholder={t('login.password_placeholder')}
            formRegister={requiredPasswordRegister}
          />
          <PasswordValidations error={errors['password1']} value={getValues('password1')}/>
          <a className={styles['forget-password']} onClick={handleForgetPassword}>{t('login.forgot_password')}</a>
        </div>
        <div className={styles['form-actions']}>
          <Button text={t('login.login')} disabled={!isDirty || !isValid} />
          <p className={styles['form-signup-text']}>{t('login.no_account')} <a onClick={()=>navigate('/signup')}>{t('login.sign_up')}</a></p>
          <div className={styles['language-switcher-container']}>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </form>
  )
}

export default LoginForm;