import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const getSingleWheel = (wheelId) => {
	return axios
		.get(`api/wheel/${wheelId}`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const spinWheel = (wheelId) => {
	return axios
		.post(`api/wheel/${wheelId}/spin`, undefined, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getWheels = () => {
	return axios
		.get('/api/wheel/', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};
