import { Swiper } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import './index.css';
import { useEffect, useState } from 'react';

export function VerticalSlider({
  children,
}) {
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      const timer = setInterval(() => {
          swiper.slideNext()
      }, 3000)
      return () => {
        clearInterval(timer);
      }
    }
  },[swiper])
  return (
    <div style={{position: 'relative',width:'100%',height: '25px',overflow: 'hidden'}}>
      <Swiper
        onSwiper={setSwiper}
        loop={true}
        speed={1000}
        // install Swiper modules
        direction={'vertical'}
        className='vertical-slider'

      >
        {children}
      </Swiper>
    </div>
  );
}

export default VerticalSlider;
