import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export default function ProgressSteps({minStep, maxStep, current}) {
  const { t } = useTranslation();
  
  return (
    <div className={styles['progress']}>
      {
        Array.from({ length: ((maxStep - minStep) / 5) + 1 }, (_, i) => (i + 1) * 5).map((step, index) => 
          <div key={index} className={`${styles['progress-step']} ${step === current ?
            styles['progress-step-active'] : ''} ${step < current ?
            styles['progress-step-completed'] : styles['progress-step-uncompleted']}`}>
            <p>{step} <span>{t('currency')}</span></p>
          </div>
        )
      }
    </div>
  )
}
