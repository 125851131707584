import logo from '../../assets/images/logo.png';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import FormInput from '../formInput';
import styles from './index.module.scss';
import { FormCheckbox } from '../formCheckbox';
import Button from '../formButton';
import LanguageSwitcher from '../languageSwitcher';
import { useTranslation } from 'react-i18next';
import { useHookForm } from '../../hooks/hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import PasswordValidations from '../passwordValidations';

export function SignupForm({ handleFormSubmit, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
		errors,
		isDirty,
		isValid,
		emailRegister,
		phoneRegister,
		fullNameRegister,
		passwordRegister,
    termsRegister,
    setValue,
    dirtyFields,
    getValues
  } = useHookForm({
    isPhone: true,
    isEmail: true,
    isFullName: true,
    isPassword: true,
    isTerms: true,
  });

  useEffect(() => {
    if (data) {
      setValue('mobile_number', data.mobile_number);
      setValue('full_name', data.full_name);
    }
  }, [])
  
  const handleHomeNavigation = () => { 
    navigate('/');
  }

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={styles['form']}>
      <img onClick={handleHomeNavigation} className={styles['form-logo']} src={logo} alt='logo' />
      <div className={styles['form-content']}>
        <h3 className={styles['form-content-title']}>{t('signup.title')}</h3>
        <p className={styles['form-content-body']}>{t('signup.description')}</p>
        <div className={styles['form-input-container']}>
          <FormInput
            name='mobile_number'
            label={
              <>
                <img src={egyptIcon} alt='egypt flag' />
                <span>
                +20
                </span>
              </>
            }
            error={errors['mobile_number']}
            placeholder={t('signup.phone_placeholder')}
            maxLength={10}
            formRegister={phoneRegister}
          />
          <FormInput
            name='full_name'
            error={errors['full_name']}
            placeholder={t('signup.fullname_placeholder')}
            formRegister={fullNameRegister}
          />
          <FormInput
            name='email'
            error={errors['email']}
            placeholder={t('signup.email_placeholder')}
            formRegister={emailRegister}
          />
          <FormInput
            name='password1'
            type='password'
            error={errors['password1']}
            placeholder={t('signup.password_placeholder')}
            formRegister={passwordRegister}
          />
          <PasswordValidations isDirty={dirtyFields['password1']} error={errors['password1']} value={getValues('password1')}/>
          <FormCheckbox
            name='accept-terms'
            label={
              <p>{t('signup.accept')}
                <a href='/terms-of-use' target='_blank'>
                  {t('signup.terms')}
                </a>
              </p>
            }
            error={errors['accept-terms']}
            formRegister={termsRegister}
          />
        </div>
        <div className={styles['form-actions']}>
          <Button text={t('signup.signup_button')} disabled={!isValid || !isDirty} />
          <p className={styles['form-login-text']}>{t('signup.have_account')} <a onClick={()=>navigate('/login')}>{t('signup.login')}</a></p>
          <div className={styles['language-switcher-container']}>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </form>
  )
}

export default SignupForm;