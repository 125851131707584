import styles from './index.module.scss';
import FormInput from '../../components/formInput';
import { useHookForm } from '../../hooks/hook-form';
import emailIcon from '../../assets/icons/email.svg';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import FormTextarea from '../../components/formTextarea';
import { useTranslation } from 'react-i18next';
import { createSupportTicket } from '../../services/sharedService';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/slices/snackbarSlice';

export function HelpAndSupport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
		errors,
		isDirty,
    isValid,
		phoneRegister,
    requiredEmailRegister,
    fullNameRegister,
    messageRegister,
    getValues
  } = useHookForm({
    isFullName: true,
    isPhone: true,
    isRequiredEmail: true,
    isMessage: true,
  });

  const onFormSubmit = async (data) => {
    const payload = {
      phone_number: data.mobile_number,
      full_name: data.full_name,
      email_address: data.email,
      message: data.message
    }
    const response = await createSupportTicket(payload);
    if (response.status === 200) {
      dispatch(setSnackbar({
        display: true,
        variant: 'success',
        message: t('my_profile.help_success_message')
      }))
    } else {
      dispatch(setSnackbar({
        display: true,
        variant: 'error',
        message: response.response.data.message
      }))
    }
  }

  return (
    <div className={styles['help']}>
      <h2 className={styles['help-header']}>{t('help.title')}</h2>
      <div className={styles['help-main']}>
        <div className={styles['help-email']}>
          <img src={emailIcon} className={styles['help-email-image']} alt='email us' />
          <p className={styles['help-email-text']}>
            <a href='emailto:seifsabry@kazbanakazbana.com'>
              {t('help.email')} <span className={styles['help-email-email']}>seifsabry@kazbanakazbana.com</span>
            </a>
          </p>
        </div>
        <form onSubmit={handleSubmit(onFormSubmit)} noValidate className={styles['help-contact']}>
          <h3 className={styles['help-contact-header']}>{t('help.contact')}</h3>
          <label className={styles['help-contact-label']}>
            {t('help.fullname_placeholder')}
            <FormInput
              name='full_name'
              placeholder={t('help.fullname_placeholder')}
              maxLength={100}
              formRegister={fullNameRegister}
              error={errors['full_name']}
            />
          </label>
          <label className={styles['help-contact-label']}>
            {t('help.phone_placeholder')}
            <FormInput
              name='mobile_number'
              label={
                <>
                  <img src={egyptIcon} alt='egypt flag' />
                  <span>
                  +20
                  </span>
                </>
              }
              error={errors['mobile_number']}
              placeholder={t('help.phone_placeholder')}
              maxLength={10}
              formRegister={phoneRegister}
            />
          </label>
          <label className={styles['help-contact-label']}>
            {t('help.email_placeholder')}
            <FormInput
              name='email'
              error={errors['email']}
              placeholder={t('help.email')}
              formRegister={requiredEmailRegister}
            />
          </label>
          <label className={styles['help-contact-label']}>
            {t('help.message')}
            <FormTextarea
              name='message'
              error={errors['message']}
              placeholder={t('help.message_placeholder')}
              formRegister={messageRegister}
            />
          </label>
        </form>
      </div>
    </div>
  )
}

export default HelpAndSupport;
