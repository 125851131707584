import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export function PrivacyAndPolicy() {
	const { t } = useTranslation();

	return (
		<div className={styles['terms-of-use']}>
			<h2 className={styles['terms-of-use-header']}>{t('privacy.title')}</h2>
			<div className={styles['terms-of-use-main']}>
				<div className={styles['terms-of-use-main-section']}>
					<h3 className={styles['terms-of-use-main-section-title']}>{t('privacy.title')}</h3>
					<div className={styles['terms-of-use-main-section-text']} dangerouslySetInnerHTML={{ __html: t('privacy.desc') }} />
				</div>
			</div>
		</div>
	);
}

export default PrivacyAndPolicy;
