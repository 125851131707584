import Button from '../formButton';
import styles from './index.module.scss';
import referralImage from '../../assets/images/referral-image.png';
import { useTranslation } from 'react-i18next';

export default function ReferralModal({onModalSubmit,message}) {
  const { t } = useTranslation();

  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <img src={referralImage} alt='winner' className={styles['modal-image']} />
        <div className={styles['modal-content']}>
          <p className={styles['modal-text']}>{message}</p>
          <p className={styles['modal-text-secondary']}>{t('navbar.referral_description')}</p>
          <Button handleSubmit={onModalSubmit} text={t('navbar.invite')} type={'button'} />
        </div>
      </div>
    </div>
  )
}
