import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { getWalletHistory } from '../../services/tabServices';
import { useSelector } from 'react-redux';
import discountBg from '../../assets/images/discount-bg.png';
import levelIndicator from '../../assets/icons/level-indicator.svg';
import TransactionCard from '../transactionCard';
import otherTransactionsEmpty from '../../assets/images/other-transactions-empty.png';
import DiscountIcon from '../profile/icons/discountIcon';
import selectArrow from '../../assets/icons/select-arrow.svg';
import { useNavigate } from 'react-router';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

export default function DiscountTab() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const { profile } = useSelector((state) => state.user);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleGetWalletHistory = async () => {
		setLoading(true);
		const response = await getWalletHistory('discount');
		if (response.status === 200) {
			setData(response.data);
		}
		setLoading(false);
	};

	useEffect(() => {
		handleGetWalletHistory();
	}, []);
	if (data && !loading) {
		return (
			<div className={styles['discount']}>
				<div className={styles['discount-header-container']}>
					<h3 className={styles['discount-header']}>
						{Number(profile.discount_won).toFixed(0)} {t('currency')}
					</h3>
					<img src={discountBg} alt='' />
				</div>
				<div className={styles['level']}>
					<h4 className={styles['level-header']}>{t('my_profile.my_level')}</h4>
					<div className={styles['level-progress']}>
						<div
							style={{ width: `${Math.floor(data.level) <= 1 ? 0 : Math.floor(data.level) <= 5 ? (data.level - 1) * 25 : 100}%` }}
							className={styles['progress-bar']}>
							<div className={styles['progress-bar-indicator']} aria-level={Math.floor(data.level)}>
								<img src={levelIndicator} alt='' />
							</div>
						</div>
					</div>
					<p className={styles['level-levels']}>
						<span>1</span>
						<span>2</span>
						<span>3</span>
						<span>4</span>
						<span>5</span>
					</p>
					<div className={styles['level-content']}>
						<p className={styles['level-text']}>
							{t('my_profile.spend')} {data.amount_to_level_up} {t('currency')} {t('my_profile.more')}
						</p>
						{/* <p className={styles['level-text']}>{t('my_profile.levels_multiplied')}</p> */}
					</div>
				</div>
				<div onClick={() => navigate('/my-profile/discount-store')} className={styles['level-redeem']}>
					<DiscountIcon color={'#3C3C43'} />
					{t('my_profile.redeem')}
					<img src={selectArrow} alt='' />
				</div>
				<div className={styles['discount-transactions']}>
					<h3 className={styles['discount-transactions-header']}>{t('my_profile.recent_transactions')}</h3>
					{data.wallet_history.length > 0 ? (
						<ul className={styles['discount-transactions-list']}>
							{data.wallet_history.map((transaction) => (
								<TransactionCard key={transaction.id} transaction={transaction} />
							))}
						</ul>
					) : (
						<img src={otherTransactionsEmpty} alt='' className={styles['discount-empty']} />
					)}
				</div>
			</div>
		);
	} else {
		return <Loader />;
	}
}
