import { useSearchParams } from 'react-router-dom';
import PaymentPending from '../../components/paymentPending';
import styles from './index.module.scss';
import PaymentFailure from '../../components/paymentFailure';
import PaymentSuccess from '../../components/paymentSuccess';
import { useEffect, useState } from 'react';
import { getOrderDetails } from '../../services/paymentStatusService';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader';

export function PaymentStatus() {
	const [loading, setLoading] = useState(false);
	const [wheelId, setWheelId] = useState();
	const [ticketNumber, setTicketNumber] = useState();
	const [isTopUp, setIsTopUp] = useState();
	const user = useSelector((state) => state.user);
	const [searchParams, setSearchParams] = useSearchParams();

	const amount = searchParams.get('amount_cents') / 100;
	const pending = searchParams.get('pending');
	const success = searchParams.get('success');
	const orderId = searchParams.get('order');
	const wheel = searchParams.get('wheel');
	const isPending = pending === 'true' ? true : false;
	const isSuccess = success === 'true' ? true : false;

	const getWheelId = async (id) => {
		setLoading(true);
		const response = await getOrderDetails(id);
		if (response.status === 200) {
			console.log('data', response.data);
			if (response.data.order_type === 'TOPUP') {
				setIsTopUp(true);
				setTicketNumber(response.data.wheel.ticket_number.slots.length);
				setWheelId(response.data.wheel.id);
			} else {
				setIsTopUp(false);
				setTicketNumber(response.data.wheel.ticket_number.slots.length);
				setWheelId(response.data.wheel.id);
			}
		} else {
			// handle error
		}
		setLoading(false);
	};

	useEffect(() => {
		if (user) {
			if (orderId && user.token) {
				getWheelId(orderId);
			}
		}
	}, [user]);

	if (loading) {
		return <Loader />;
	}
	return (
		<div className={styles['payment-status']}>
			{isSuccess && <PaymentSuccess wheel={wheel} amount={amount} isTopUp={isTopUp} ticketNumber={ticketNumber} wheelId={wheelId} />}
			{isPending && <PaymentPending />}
			{!isPending && !isSuccess && <PaymentFailure />}
		</div>
	);
}

export default PaymentStatus;
