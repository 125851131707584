import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import orderSlice from './slices/orderSlice';
import snackbarSlice from './slices/snackbarSlice';
import favouritesSlice from './slices/favouritesSlice';
import loadingSlice from './slices/loadingSlice';
import categoriesSlice from './slices/categoriesSlice';

export const store = configureStore({
	reducer: {
		user: userSlice,
		order: orderSlice,
		snackbar: snackbarSlice,
		favourites: favouritesSlice,
		categories: categoriesSlice,
		loading: loadingSlice,
	},
});

export default store;
