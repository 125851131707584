import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const login = async (payload) => {
	return axios
		.post('auth/login', payload)
		.then((res) => res)
		.catch((err) => err?.response);
};

export const deleteAccount = async () => {
	return axios
		.post('auth/delete-account', null, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((res) => res)
		.catch((err) => err?.response);
};
