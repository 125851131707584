import { createSlice } from '@reduxjs/toolkit';

export const favouritesSlice = createSlice({
	name: 'favourites',
	initialState: [],
	reducers: {
		setFavourites: (state, action) => {
			return action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setFavourites } = favouritesSlice.actions;

export default favouritesSlice.reducer;
