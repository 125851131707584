import { useEffect } from 'react';
import styles from './index.module.scss';
import { getAllCategories } from '../../services/categoriesService';
import CategoryChip from '../categoryChip';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories } from '../../store/slices/categoriesSlice';
import { useTranslation } from 'react-i18next';

export function CategoryHeader() {
  const categories = useSelector(state => state.categories);
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCategories = async () => {
    const response = await getAllCategories();
    if (response.status === 200) {
      dispatch(setCategories(response.data));
    } else {
      //handle error
    }
  }

  const handleCategorySubmit = (slug,name) => {
    navigate(`/category/${slug}`,{state:{name}})
  }

  useEffect(() => {
    getCategories();
  }, [i18n.language])
  
  return (
    <div className={styles['category-header']}>
      <CategoryChip onCategorySubmit={handleCategorySubmit}  category={{slug:'all',name:t('all')}} />
      {categories?.map((category) =>
        <CategoryChip key={category.id} onCategorySubmit={handleCategorySubmit}  category={category} />
      )}
    </div>
  )
}

export default CategoryHeader;
