import { useNavigate } from 'react-router';
import splash from '../../assets/images/splash.png';
import whiteLogo from '../../assets/images/white-logo.png';
import styles from './index.module.scss';

export function FormSplash() {
  const navigate = useNavigate();

  const handleHomeNavigation = () => {
    navigate('/');
  }
  
  return (
   <div className={styles['splash']}>
      <img onClick={handleHomeNavigation} className={styles['splash-logo']} src={whiteLogo} alt='kazbana logo' />
      <img className={styles['splash-image']} src={splash} alt="splash with logo" />
    </div>
  )
}

export default FormSplash;