import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useLocation, useNavigate} from 'react-router';
import FormInput from '../formInput';
import { useHookForm } from '../../hooks/hook-form';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import Button from '../formButton';
import { shipWheel } from '../../services/sharedService';
import bigWinImage from '../../assets/images/big-win-modal.png';
import { useTranslation } from 'react-i18next';

export default function DeliverToYouTab() {
  const [bigWin, setBigWin] = useState();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();


  const location = useLocation();
  const {
    handleSubmit,
		errors,
		isDirty,
    isValid,
    fullNameRegister,
    phoneRegister,
    cityRegister,
    requiredEmailRegister,
    addressRegister,
    getValues
  } = useHookForm({
      isFullName: true,
      isRequiredEmail: true,
      isPhone: true,
      isCity: true,
      isAddress: true,
    });
  const date = new Date(location.state?.bigWin.updated_at);

  const onModalSubmit = () => {
    setShowModal(false);
    navigate('/');
  };
  
  const handleFormSubmit = async (data) => {
    const payload = {
      ...data,
      phone_number: data.mobile_number,
    }
    delete payload.mobile_number;
    const response = await shipWheel(bigWin.id, payload);
    if (response.status === 200) {
      setShowModal(true);
    } else {
      //
    }
  }

  const getMonths = (month) => {
    switch (month) {
      case 0:
        return t('january');
      case 1:
        return t('february');
      case 2:
        return t('march');
      case 3:
        return t('april');
      case 4:
        return t('may');
      case 5:
        return t('june')
      case 6:
        return t('july');
      case 7:
        return t('august');
      case 8:
        return t('september');
      case 9:
        return t('october');
      case 10:
        return t('november');
      case 11:
        return t('december');
      default:
        return ''
    }
  }

  useEffect(() => {
    if (location.state?.bigWin) {
      setBigWin(location.state?.bigWin)
    }
  },[location])
  
  if (bigWin) {
    return (
      <>
      <div className={styles['deliver-container']}>
        <div className={styles['deliver-card']}>
          <img className={styles['deliver-card-image']} src={bigWin.product.thumbnail} alt={bigWin.product.name} />
          <div className={styles['deliver-card-product']}>
            <p className={styles['deliver-card-wheel']}>{t('my_profile.wheel_number')} <span>{bigWin.product.wheel_number}</span></p>
            <h3 className={styles['deliver-card-product-title']}>{bigWin.product.name}</h3>
            <p className={styles['deliver-card-product-date']}>{`${date.getDate()} ${getMonths(date.getMonth())}, ${date.getHours() > 12 ? (date.getHours() - 12).toLocaleString('en-US',{minimumIntegerDigits:2}) : date.getHours().toLocaleString('en-US',{minimumIntegerDigits:2})}:${date.getMinutes().toLocaleString('en-US',{minimumIntegerDigits: 2})} ${date.getHours() >= 12 ? t('pm') : t('am')}`}</p>
            <p className={styles['deliver-card-winner']}>{t('ticket')} <span>{bigWin.winner_ticket}</span></p>
          </div>
        </div>
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={styles['form']}>
          <div className={styles['form-input-container']}>
            <p>{t('my_profile.fullname')}</p>
            <FormInput
              name='full_name'
              error={errors['full_name']}
              placeholder={t('my_profile.fullname_placeholder')}
              formRegister={fullNameRegister}
            />
          </div>
          <div className={styles['form-input-container']}>
            <p>{t('my_profile.email')}</p>
            <FormInput
              name='email'
              error={errors['email']}
              placeholder={t('my_profile.email')}
              formRegister={requiredEmailRegister}
            />
          </div>
          <div className={styles['form-input-container']}>
              <p>{t('my_profile.phone')}</p>
            <FormInput
              name='mobile_number'
              label={
                <>
                  <img src={egyptIcon} alt='egypt flag' />
                  <span>
                  +20
                  </span>
                </>
              }
              error={errors['mobile_number']}
              placeholder={t('my_profile.phone_placeholder')}
              maxLength={10}
              formRegister={phoneRegister}
            />
          </div>
          <div className={styles['form-input-container']}>
              <p>{t('my_profile.city')}</p>
            <FormInput
              name='city'
              error={errors['city']}
              placeholder={t('my_profile.city_province')}
              maxLength={10}
              formRegister={cityRegister}
            />
          </div>
          <div className={styles['form-input-container']}>
              <p>{t('my_profile.address_details')}</p>
            <FormInput
              name='address'
              error={errors['address']}
              placeholder={t('my_profile.address_details')}
              formRegister={addressRegister}
            />
          </div>
          <Button type={'submit'} disabled={!isValid || !isDirty} text={t('my_profile.deliver_button')}/>
        </form>
      </div>
      {showModal &&
        <div className={styles['modal']}>
        <div className={styles['modal-container']}>
          <img src={bigWinImage} alt='winner' className={styles['modal-image']} />
          <div className={styles['modal-content']}>
            <p className={styles['modal-text']}>{t('my_profile.gift_on_way')}</p>
            <p className={styles['modal-text-secondary']}>{t('my_profile.gift_desc')}</p>
            <Button handleSubmit={onModalSubmit} text={t('my_profile.confirm_button')} type={'button'} />
          </div>
        </div>
      </div>
      }
      </>
    )
  }
}
