import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import bigWinsEmpty from '../../assets/images/big-wins-empty.png';
import Button from '../formButton';
import { getBigWins } from '../../services/tabServices';
import { useNavigate } from 'react-router';
import BigWinCard from '../BigWinCard';
import ticketAnimation from '../../assets/images/ticket-animation.gif';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

export default function BigWinsTab() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateToCategories = () => {
    navigate('/category/all')
  }
  const handleGetBigWins = async () => {
    setLoading(true);
    const response = await getBigWins();
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGetBigWins()
  }, [])
  
  if (data && !loading) {
    return (
      <>
        {data.length > 0 ?
          <div className={styles['big-wins']}>
            <ul className={styles['big-wins-list']}>
              {data.map(bigWin =>
                <BigWinCard key={bigWin.id} bigWin={bigWin} />
              )}
            </ul>
          </div> :
          <div className={styles['big-wins-empty']}>
            <h3 className={styles['big-wins-empty-number']}>0</h3>
            <p className={styles['big-wins-empty-text']}>{t('my_profile.big_win_empty_title')}</p>
            <img className={styles['big-wins-empty-image']} src={bigWinsEmpty} alt='' />
            <div className={styles['big-wins-empty-action']}>
              <p className={styles['big-wins-empty-hint']}>{t('my_profile.big_win_empty_desc')}</p>
              <Button handleSubmit={handleNavigateToCategories} type={'button'} text={t('my_profile.view_products')}>
                <img className={styles['big-wins-empty-action-image']} src={ticketAnimation} alt='' />
              </Button>
            </div>
          </div>
        }
      </>
    )
  } else {
    return (
      <Loader/>
    )
  }
}
