import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { getWalletHistory } from '../../services/tabServices';
import { useSelector } from 'react-redux';
import balanceBg from '../../assets/images/balanceBg.png';
import Button from '../formButton';
import { useNavigate } from 'react-router';
import BalanceTransactionCard from '../balanceTransactionCard';
import otherTransactionsEmpty from '../../assets/images/other-transactions-empty.png';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

export default function BalanceTab() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { profile } = useSelector(state => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const handleGoToTopUp = () => {
    navigate('/my-profile/top-up')
  }
  const handleGetWalletHistory = async () => {
    setLoading(true);
    const response = await getWalletHistory('balance');
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGetWalletHistory()
  }, [])
  if (data && !loading) {
    return (
      <div className={styles['balance']}>
        <div className={styles['balance-header-container']}>
          <h3 className={styles['balance-header']}>{profile.available_balance} {t('currency')}</h3>
          <img src={balanceBg} alt=''/>
        </div>
        <p className={styles['balance-hint']}>{t('my_profile.top_up_desc')}</p>
        <Button text={t('my_profile.topup_button')} handleSubmit={handleGoToTopUp} type={'button'}/>
        <div className={styles['balance-transactions']}>
          <h3 className={styles['balance-transactions-header']}>{t('my_profile.recent_transactions')}</h3>
          {data.wallet_history.length > 0 ?
            <ul className={styles['balance-transactions-list']}>
              {data.wallet_history.map(transaction => 
                <BalanceTransactionCard key={transaction.id} level={data.level} transaction={transaction} />
              )}
            </ul> :
            <img src={otherTransactionsEmpty} className={styles['balance-empty']} alt='' />
          }
        </div>
      </div>
    )
  } else {
    return (
      <Loader />
    )
  }
}
