import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
	name: 'snackbar',
	initialState: {
		display: false,
		message: '',
		variant: 'success',
	},
	reducers: {
		setSnackbar: (state, action) => {
			return action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
