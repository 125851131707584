import styles from './index.module.scss';
import cart from '../../assets/icons/cart.svg';
import talabatTicket from '../../assets/images/talabat-ticket.png';
import Button from '../formButton';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function SuccessVoucherModal({onModalSubmit,voucher}) {
  const [textToCopy, setTextToCopy] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCopyText = () => {
    dispatch(setSnackbar({
      display: true,
      variant: 'success',
      message: t('code_copied')
    }))
  }

  useEffect(() => {
    setTextToCopy(voucher);
  }, []);

  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-content']}>
          <img className={styles['modal-content-cart']} src={cart} alt='' />
          <p className={styles['modal-content-text']}>{t('my_profile.congratulations')}<br />{t('my_profile.claimed')}</p>
          <img className={styles['modal-content-ticket']} src={talabatTicket} alt='' />
          <p className={styles['modal-content-voucher']}>{t('my_profile.voucher_code')}
            <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
              <span className={styles['code']}>{voucher}</span>
            </CopyToClipboard>
          </p>
          <p className={styles['modal-content-hint']}>{t('my_profile.go_to_app')}</p>
          <Button handleSubmit={onModalSubmit} text={t('my_profile.done_button')} type={'button'} />
        </div>
      </div>
    </div>
  )
}
