import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const retriveProfile = () => {
	return axios
		.get('/api/account/profile', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const updateProfile = (payload) => {
	return axios
		.patch('/api/account/profile/', payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};
