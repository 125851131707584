import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
	name: 'order',
	initialState: {
		order: null,
	},
	reducers: {
		addOrder: (state, action) => {
			return action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addOrder } = orderSlice.actions;

export default orderSlice.reducer;
