import styles from './index.module.scss';
import correct from '../../assets/icons/correct.svg';
import errorIcon from '../../assets/icons/error.svg';
import { useTranslation } from 'react-i18next';

export function PasswordValidations({ value, isDirty, error }) {
  const {t} = useTranslation();
  const oneNumberRegex = /^(?=.*?[0-9]).{1,}$/;
  const oneSmallCaseRegex = /^(?=.*?[a-z]).{1,}$/;
  const oneCapitalCaseRegex = /^(?=.*?[A-Z]).{1,}$/;
  const oneSpecialCharRegex = /^(?=.*?[#?!@$%^&*-]).{1,}$/;

  const oneNumber = oneNumberRegex.test(value);
  const oneSmallCase = oneSmallCaseRegex.test(value);
  const oneCapitalCase = oneCapitalCaseRegex.test(value);
  const oneSpecialChar = oneSpecialCharRegex.test(value);

  return (
    <>
      {isDirty && 
        <div>
          <p
            className={[
              styles['validation'],
              !isDirty ? styles['normal'] : oneSmallCase ? styles['success'] : styles['error']
            ].join(' ')}
          >
            {
              !isDirty ?
                <></> :
                oneSmallCase ?
                  <img src={correct} className={styles['validation-icon']} alt='correct' /> :
                  <img src={errorIcon} className={styles['validation-icon']}  alt='invalid'/>
            }
            {t('password.one_small_case')}
          </p>
          <p
            className={[
              styles['validation'],
              !isDirty ? styles['normal'] : oneCapitalCase ? styles['success'] : styles['error']
            ].join(' ')}
          >
            {
              !isDirty ?
                <></> :
                oneCapitalCase ?
                  <img src={correct} className={styles['validation-icon']} alt='correct' /> :
                  <img src={errorIcon} className={styles['validation-icon']}  alt='invalid'/>
            }
            {t('password.one_capital_case')}
          </p>
          <p
            className={[
              styles['validation'],
              !isDirty ? styles['normal'] : oneNumber ? styles['success'] : styles['error']
            ].join(' ')}
          >
            {
              !isDirty ?
                <></> :
                oneNumber ?
                  <img src={correct} className={styles['validation-icon']} alt='correct' /> :
                  <img src={errorIcon} className={styles['validation-icon']}  alt='invalid'/>
            }
            {t('password.one_number')}
          </p>
          <p
            className={[
              styles['validation'],
              !isDirty ? styles['normal'] : oneSpecialChar ? styles['success'] : styles['error']
            ].join(' ')}
          >
            {
              !isDirty ?
                <></> :
                oneSpecialChar ?
                  <img src={correct} className={styles['validation-icon']} alt='correct' /> :
                  <img src={errorIcon} className={styles['validation-icon']}  alt='invalid'/>
            }
            {t('password.one_special_character')}
          </p>
          <p
            className={[
              styles['validation'],
              !isDirty ? styles['normal'] : value?.length >= 8 && value?.length <= 40 ? styles['success'] : styles['error']
            ].join(' ')}
          >
            {
              !isDirty ?
                <></> :
                value?.length >= 8 && value?.length <= 40 ?
                  <img src={correct} className={styles['validation-icon']} alt='correct' /> :
                  <img src={errorIcon} className={styles['validation-icon']}  alt='invalid'/>
            }
            {t('password.length')}
          </p>
        </div>
      }
      {!isDirty && value === '' && error?.message &&
        <p className={[styles['validation'],styles['error']].join(' ')}>{error?.message}</p>
      }
    </>
  )
}

export default PasswordValidations;