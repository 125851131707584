import styles from './index.module.scss';
import navbarEnLogo from '../../assets/images/navbarEnLogo.png';
import navbarArLogo from '../../assets/images/navbarArLogo.png';
import userIcon from '../../assets/icons/user.svg';
import emptyHeart from '../../assets/icons/empty-heart.svg'
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import avatar from '../../assets/icons/avatar.svg';
import balance from '../../assets/icons/navbar-balance.svg';
import notificationsIcon from '../../assets/icons/notification.svg';
import NotificationMenu from '../notificationMenu';
import { useEffect, useRef, useState } from 'react';
import { getNotifications } from '../../services/sharedService';
import { setProfile } from '../../store/slices/userSlice';
import { retriveProfile } from '../../services/profileService';
import MobileMenu from '../mobileMenu';
import ReferralModal from '../referralModal';

export function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const favourites = useSelector(state => state.favourites);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navbarRef = useRef();
  const dispatch = useDispatch();

  const handleLoginNavigation = () => {
    handleCloseMenu()
    navigate('/login');
  };
  const handleHomeNavigation = () => {
    handleCloseMenu()
    navigate('/');
  };
  const handleTopup = () => {
    handleCloseMenu()
    navigate('/my-profile/top-up');
  };
  const handleGoToProfile = () => {
    handleCloseMenu()
    navigate('/my-profile/available-balance')
  };
  const handleGoToFavourites = () => {
    handleCloseMenu()
    navigate('/my-profile/favourites')
  }
  const handleGetNotifications = async () => {
		const response = await getNotifications();
    if (response.status === 200) {
      setNotifications(response.data);
		}
  };
  const handleOpenNotifications = () => {
    handleCloseMenu()
    if (user.token) {
      setOpenNotifications(true);
    } else {
      navigate('/login');
    }
  }
  const getProfile = async () => {
    const response = await retriveProfile();
    if (response.status === 200) {
      dispatch(setProfile(response.data))
    } else {
      //handle error
    }
  };
  const handleCloseNotifications = () => {
    setOpenNotifications(false);
  }
  const handleOpenMenu = () => {
    setOpenMenu(true);
  }
  const handleCloseMenu = () => {
    setOpenMenu(false);
  }
  const handleModalSubmit = () => {
    setOpenReferralModal(false);
    navigate('/my-profile/refer-friend');
  }

  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.scrollIntoView({behavior: "smooth"})
    }  
  }, [location]);

  useEffect(() => {
		if (user.token) {
      handleGetNotifications();
      getProfile();
		}
	}, [location]);

  return (
    <nav ref={navbarRef} className={styles['navbar']}>
      <div onClick={handleOpenMenu} className={styles['navbar-menu']}>
        <div className={styles['navbar-menu-item']}></div>
        <div className={styles['navbar-menu-item']}></div>
        <div className={styles['navbar-menu-item']}></div>
      </div>
      <img onClick={handleHomeNavigation} src={i18n.language === 'en-US' ? navbarEnLogo : navbarArLogo} className={styles['navbar-logo']} alt='kazbanakazbana logo' />
      <ul className={styles['navbar-list']}>
        {!user.user && (
          <li onClick={handleLoginNavigation} className={styles['navbar-list-item']}>
            <img className={styles['navbar-list-item-icon']} src={userIcon} alt='sign-up' />
            <p className={styles['navbar-list-item-text']}>{t('navbar.signup_signin')}</p>
          </li>
        )}
        {user.user && (
          <li onClick={handleGoToProfile} className={styles['navbar-list-item']}>
            {user.profile?.profile_picture ?
              <img className={styles['navbar-list-item-image']} src={user.profile.profile_picture} alt={user.user.full_name} />:
              <img className={styles['navbar-list-item-image']} src={avatar} alt='empty avatar' />
            }
            <p className={styles['navbar-list-item-text']}>{t('navbar.hello')} {user.user.full_name.split(' ')[0]}</p>
          </li>
        )}
        <li onClick={handleGoToFavourites} className={styles['navbar-list-item']}>
          <div className={styles['navbar-favourites']}>
            <img src={emptyHeart} className={styles['navbar-list-item-icon']} alt='' />
            {user.user && favourites.length > 0 ?
              <p className={styles['navbar-favourites-number']}>{favourites.length}</p>
              : <></>
            }
          </div>
        </li>
        <li onClick={handleOpenNotifications} className={styles['navbar-list-item']}>
          <div className={styles['navbar-favourites']}>
            <img src={notificationsIcon} className={styles['navbar-list-item-icon']} alt='' />
            {user.user && notifications?.filter(notification => !notification.read_at).length > 0 ?
              <p className={styles['navbar-favourites-number']}>{notifications.filter(notification => !notification.read_at).length}</p>
              : <></>
            }
          </div>
        </li>
        {user.profile &&
          <li onClick={handleGoToProfile} className={`${styles['navbar-list-item']} ${styles['navbar-balance']}`}>
            <img src={balance} className={styles['navbar-list-item-icon']} alt='' />
            <p className={`${styles['navbar-list-item-text']} ${styles['navbar-balance-text']}`}>{user.profile.available_balance}<span>{t('currency')}</span></p>
          </li>
        }
        {!user.profile &&
          <li onClick={handleLoginNavigation} className={`${styles['navbar-list-item']} ${styles['navbar-balance']}`}>
            <img src={balance} className={styles['navbar-list-item-icon']} alt='' />
            <p className={`${styles['navbar-list-item-text']} ${styles['navbar-balance-text']}`}>0<span>{t('currency')}</span></p>
          </li>
        }
        {user.profile &&
          <li onClick={handleTopup} className={`${styles['navbar-list-item']} ${styles['navbar-topup']}`}>
            <p className={`${styles['navbar-list-item-text']} ${styles['navbar-topup-text']}`}>{t('navbar.topup')}</p>
          </li>
        }
        {!user.profile &&
          <li onClick={handleLoginNavigation}
          className={`${styles['navbar-list-item']} ${styles['navbar-topup']}`}>
            <p className={`${styles['navbar-list-item-text']} ${styles['navbar-topup-text']}`}>{t('navbar.topup')}</p>
          </li>
        }
      </ul>
      <NotificationMenu setOpenReferralModal={setOpenReferralModal} setMessage={setMessage} open={openNotifications} setNotifications={setNotifications} notifications={notifications} handleCloseNotifications={handleCloseNotifications} />
      <MobileMenu open={openMenu} setOpen={setOpenMenu} notifications={notifications} handleOpenNotifications={handleOpenNotifications} handleCloseMenu={handleCloseMenu} />
      {openReferralModal &&
        <ReferralModal open={openReferralModal} message={message} onModalSubmit={handleModalSubmit} setOpen={setOpenReferralModal} />
      }
    </nav>
  )
}

export default Navbar;
