import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { getPurchasedTickets } from '../../services/tabServices';
import otherTransactionsEmpty from '../../assets/images/other-transactions-empty.png';
import OtherTransactionsCard from '../otherTransactionsCard';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';

export default function OtherTransactionsTab() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { t } = useTranslation();

  const handleGetPurchasedTickets = async () => {
    setLoading(true);
    const response = await getPurchasedTickets();
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGetPurchasedTickets()
  }, [])
  if (data && !loading) {
    return (
      <div className={styles['other']}>
        <div className={styles['other-transactions']}>
          {data.length > 0 ?
            <>
              <h3 className={styles['other-transactions-header']}>{t('my_profile.recent_transactions')}</h3>
              <ul className={styles['other-transactions-list']}>
                {data.map(transaction => 
                  <OtherTransactionsCard key={transaction.paymob_order_id} transaction={transaction} />
                )}
              </ul>
            </> :
            <img className={styles['other-transactions-empty']} src={otherTransactionsEmpty} alt='' />
          }
        </div>
      </div>
    )
  } else {
    return (
      <Loader/>
    )
  }
}
