import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

export function LiveWheelAnimation({handleFinishCounter}) {
  const [counter, setCounter] = useState(10);
  const [timerId, setTimerId] = useState(null);
  const renderCount = useRef(1);
  const { t } = useTranslation();

  useEffect(() => {
    if (renderCount?.current === 1 && !timerId) {
      const id = setInterval(() => {
        setCounter(prev => prev - 1);
      }, 1000);
      setTimerId(id);
      renderCount.current++;
    } else if (timerId && counter === 0) {
      clearInterval(timerId)
      setTimerId(null);
      // handle finish countdown
      handleFinishCounter();
    }
  }, [counter]);

  return (
    <div className={styles['live-wheel']}>
      <p className={styles['live-wheel-text']}>
        {t('live_wheel.live_wheel')}
        <span className={styles['live-wheel-text-in']}>{t('live_wheel.in')}</span>
      </p>
      <p className={styles['live-wheel-number']}>{counter}</p>
    </div>
  )
}

export default LiveWheelAnimation;