import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
	const user = useSelector((state) => state.user);
	let location = useLocation();
	let protectedRoutes = ['my-profile', 'checkout', 'live-wheel'];
	let authRoutes = ['login', 'signup'];
	if (!user.user && protectedRoutes.includes(location.pathname.split('/')[1])) {
		return <Navigate to='/login' state={{ from: location }} replace />;
	} else if (user.user && authRoutes.includes(location.pathname.split('/')[1])) {
		return <Navigate to='/' state={{ from: location }} replace />;
	}
	return children;
};

export default ProtectedRoute;
