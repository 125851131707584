import styles from './index.module.scss';

export function Radio({handleClick,id,value,label,selected,icons,children,name}) {
  return (
    <div className={styles['container']}>
      <input
        className={styles['radio-input']}
        type="radio"
        id={id.toString()}
        name={name}
        value={value}
        checked={selected === value}
        onClick={() => handleClick(value)}
      />
      <label
        className={styles['radio-input-lable']}
        htmlFor={id.toString()}
      >
        <div className={styles['radio-input-content']}>
          <span className={styles['radio-input-lable-text']}>
            {label}
          </span>
          {icons && icons.length > 0 &&
            <div className={styles['radio-input-lable-container']}>
              {icons.map((icon, index) => 
                <img key={index} className={styles['radio-input-lable-image']} src={icon.url} alt={icon.name} />
              )}
            </div>
          }
        </div>
        {children}
      </label>
    </div>
  );
}

export default Radio;