import styles from './index.module.scss';
import Button from '../formButton';
import close from '../../assets/icons/close.svg';
import { retriveProfile, updateProfile } from '../../services/profileService';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile, setUser } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router';
import { useHookForm } from '../../hooks/hook-form';
import FormInput from '../formInput';
import egyptIcon from '../../assets/icons/egypt-icon.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ProgressBar from '../progressBar';
import deleteIcon from '../../assets/icons/delete.svg';
import upload from '../../assets/icons/upload.svg';
import { useTranslation } from 'react-i18next';

export function ProfileData({ setShowModal, handleModalClose }) {
  const [file, setFile] = useState();
  const [fileShow, setFileShow] = useState();
  const [progress, setProgress] = useState(0);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    handleSubmit,
    errors,
    isValid,
    trigger,
		phoneRegister,
    fullNameRegister,
    emailRegister,
    setValue
  } = useHookForm({
    isPhone: true,
    isEmail: true,
    isFullName: true,
  });

  useEffect(() => {
    if (user.profile) {
      setValue('mobile_number', user.profile.mobile_number.slice(1));
      setValue('full_name', user.profile.full_name);
      setValue('email', user.profile.email);
      trigger()
    }
    if (user.profile?.profile_picture) {
      setFileShow(user.profile.profile_picture)
    }
  },[])
  
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileShow(URL.createObjectURL(e.target.files[0]))
  }
  const handleDeleteImage = () => {
    setFile(null);
    setFileShow(null)
  }
  const handleFormSubmit = async (data) => {
    const formData = new FormData();
    let payload = {};
    if (file) {
      formData.append("profile_picture", file);
    } else if (!fileShow) {
      payload['profile_picture'] = null;
      }
      if (data.full_name) {
        formData.append("full_name",data.full_name);
        payload['full_name'] = data.full_name;
        }
        if (data.email) {
          formData.append("email",data.email);
          payload['email'] = data.email;
    }
    const response = file ? await axios({
      method: "patch",
      url: '/api/account/profile/',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${user.token}`
      },
      onUploadProgress: (progressEvent) => {
        setProgress(Math.floor(progressEvent.progress * 100))
      },
    }) : await axios({
      method: "patch",
      url: '/api/account/profile/',
      data: payload,
      headers: {
        'Authorization': `Token ${user.token}`
      },
    });
    if (response.status === 200) {
      const profileResponse = await retriveProfile();
      if (profileResponse.status === 200) {
        setShowModal(false);
        dispatch(setProfile(profileResponse.data));
      } else if (profileResponse.status === 401) {
        navigate('/login')
        // handle error
      }
    } else if (response.status === 401) {
      navigate('/login')
      // handle error
    }
  }
  
  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-header']}>
          <img onClick={handleModalClose} src={close} alt='close' className={styles['modal-header-close']} />
          <h3 className={styles['modal-header-text']}>{t('my_profile.edit_profile')}</h3>
        </div>
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={styles['modal-content']}>
          {fileShow ?
            <div className={styles['modal-image']}>
              <div onClick={handleDeleteImage} className={styles['modal-image-delete-container']}>
                <img  className={styles['modal-image-delete']} alt='delete' src={deleteIcon} />
              </div>
              <div className={styles['progress-container']}>
                <ProgressBar progress={progress} />
              </div>
              <img src={fileShow} className={styles['modal-image-show']} alt='' />
            </div> :
            <label className={styles['modal-image-input-label']}>
              <img src={upload} className={styles['modal-image-input-label-image']} alt='upload image' />
              <input className={styles['modal-image-input']} type='file' accept="image/*" onChange={handleChange}/>
            </label>
          }
          <div className={styles['modal-content-container']}>
            <FormInput
              name='full_name'
              error={errors['full_name']}
              placeholder={t('my_profile.fullname_placeholder')}
              formRegister={fullNameRegister}
            />
            <FormInput
              name='mobile_number'
              label={
                <>
                  <img src={egyptIcon} alt='egypt flag' />
                  <span>
                  +20
                  </span>
                </>
              }
              error={errors['mobile_number']}
              placeholder={t('my_profile.phone_placeholder')}
              disabled={true}
              value={user.user.mobile_number}
              maxLength={10}
              formRegister={phoneRegister}
            />
            <FormInput
              name='email'
              error={errors['email']}
              placeholder={t('my_profile.email_placeholder')}
              formRegister={emailRegister}
            />
          </div>
          <Button disabled={!isValid} type={'submit'} text={t('my_profile.submit_button')}/>
        </form>
      </div>
    </div>
  )
}

export default ProfileData;