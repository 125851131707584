import styles from './index.module.scss';
import emptyNotificationsIcon from '../../assets/images/notification-empty.png';
import close from '../../assets/icons/close.svg';
import NotificationCard from '../notificationCard';
import { useEffect } from 'react';
import { getNotifications, readNoAction } from '../../services/sharedService';
import { useTranslation } from 'react-i18next';

export default function NotificationMenu({setNotifications,notifications,open,handleCloseNotifications,setOpenReferralModal,setMessage}) {
  const { t } = useTranslation();
  
  const handleReadNotifications = async () => {
    const response = await readNoAction();
    if (response.status === 200) {
      const notificationsRes = await getNotifications();
      if (notificationsRes.status === 200) {
        setNotifications(notificationsRes.data)
      }
    }
  }

  useEffect(() => {
    handleReadNotifications();
  }, []);

  return (
    <div onClick={handleCloseNotifications} className={`${styles['notifications']} ${open ? styles['notifications-open'] : ''}`}>
      {notifications.length > 0 ? 
        <div className={styles['notifications-container']}>
          <img onClick={handleCloseNotifications} src={close} alt='close' className={styles['notifications-close']} />
          <h4 className={styles['notifications-container-header']}>{t('notifications')}</h4>
          <ul className={styles['notifications-list']}>
            {notifications?.map(notification => 
              <li key={notification.id} className={styles['notifications-list-item']}>
                <NotificationCard setMessage={setMessage} setOpenReferralModal={setOpenReferralModal} handleCloseNotifications={handleCloseNotifications} setNotifications={setNotifications} notification={notification} />
              </li>
            )}
          </ul>
        </div> :
        <div className={styles['notifications-empty-container']}>
          <img onClick={handleCloseNotifications} src={close} alt='close' className={styles['notifications-close']} />
          <img src={emptyNotificationsIcon} className={styles['notifications-empty-container-image']} alt='' />
        </div>
      }
    </div>
  )
}
