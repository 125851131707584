import styles from './index.module.scss';
import whiteLogo from '../../assets/images/white-logo.png';
// import whatsappLogo from '../../assets/icons/whatsapp.svg';
// import phoneLogo from '../../assets/icons/Call.svg';
import appStore from '../../assets/images/appStore.png';
import playStore from '../../assets/images/playStore.png';
import copyrightsLogo from '../../assets/images/copyrightsLogo.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function Footer() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const categories = useSelector((state) => state.categories);
	return (
		<footer className={styles['footer']}>
			<div className={styles['footer-sections']}>
				<div className={styles['footer-first']}>
					<img src={whiteLogo} alt='kasbana logo' className={styles['footer-first-logo']} />
					{/* <div className={styles['footer-first-contact']}>
            <h3 className={styles['footer-first-contact-header']}>
              Contact us
            </h3>
            <div className={styles['footer-first-contact-method']}>
              <img src={whatsappLogo} alt='whatsapp' className={styles['footer-first-contact-method-image']}/>
              <p className={styles['footer-first-contact-method-name']}>
                Whatsapp
                <span className={styles['footer-first-contact-method-number']}>+1 202-918-2132</span>
              </p>
            </div>
            <div className={styles['footer-first-contact-method']}>
              <img src={phoneLogo} alt='call us' className={styles['footer-first-contact-method-image']}/>
              <p className={styles['footer-first-contact-method-name']}>
                Call us
                <span className={styles['footer-first-contact-method-number']}>+1 202-918-2132</span>
              </p>
            </div>
          </div> */}
					<div className={styles['footer-first-download']}>
						<h3 className={styles['footer-first-download-header']}>{t('footer.download')}</h3>
						<div className={styles['footer-first-download-methods']}>
							<a href='#' className={styles['footer-first-download-method']}>
								<img src={appStore} className={styles['footer-first-download-method-image']} alt='app store download' />
							</a>
							<a href='#' className={styles['footer-first-download-method']}>
								<img src={playStore} className={styles['footer-first-download-method-image']} alt='play store download' />
							</a>
						</div>
					</div>
				</div>
				<div className={styles['footer-second']}>
					<h3 className={styles['footer-second-header']}>{t('footer.popular_categories')}</h3>
					<ul className={styles['footer-second-list']}>
						{categories.length > 0 &&
							categories.map((category) => (
								<li
									key={category.id}
									onClick={() => {
										navigate(`/category/${category.slug}`, { state: { name: category.name } });
									}}
									className={styles['footer-second-list-item']}>
									{category.name}
								</li>
							))}
					</ul>
				</div>
				<div className={styles['footer-third']}>
					<h3 className={styles['footer-third-header']}>{t('footer.customer_services')}</h3>
					<ul className={styles['footer-third-list']}>
						<li onClick={() => navigate('/terms-of-use')} className={styles['footer-third-list-item']}>
							{t('footer.terms')}
						</li>
						<li onClick={() => navigate('/privacy-and-policy')} className={styles['footer-third-list-item']}>
							{t('footer.privacy')}
						</li>
						<li onClick={() => navigate('/my-profile/settings')} className={styles['footer-third-list-item']}>
							{t('footer.settings')}
						</li>
						<li onClick={() => navigate('/my-profile/help-and-support')} className={styles['footer-third-list-item']}>
							{t('footer.help')}
						</li>
					</ul>
				</div>
			</div>
			<div className={styles['footer-copyrights']}>
				<p className={styles['footer-copyrights-text']}>{t('footer.copyrights')}</p>
				<img src={copyrightsLogo} alt='kasbanakasbana' className={styles['footer-copyrights-logo']} />
			</div>
		</footer>
	);
}

export default Footer;
