import FormSplash from '../../components/formSplash';
import LoginForm from '../../components/loginForm';
import backArrow from '../../assets/icons/back-arrow.svg';
import styles from './index.module.scss';
import { login } from '../../services/loginService';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import ForgetPasswordForm from '../../components/forgetPasswordForm';
import OTPStep from '../../components/otpStep';
import { forgetPassword, sendOTP } from '../../services/authSharedService';
import Button from '../../components/formButton';
import SuccessStep from '../../components/successStep';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { getFavouriteProducts } from '../../services/sharedService';
import { setFavourites } from '../../store/slices/favouritesSlice';
import { useTranslation } from 'react-i18next';

export function Login() {
  const [step, setStep] = useState(1);
  const [data, setData] = useState();
  const [OTP, setOTP] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLoginFormSubmit = async (data) => {
    const payload = {
      username: '0' + data.mobile_number,
      password: data.password1
    };
    const response = await login(payload);
    if (response.status === 200) {
      dispatch(setSnackbar({
        variant: 'success',
        display: true,
        message: t('success_login')
      }));
      dispatch(setUser({
        token: response.data.key,
        user: response.data.user,
        profile: response.data.profile
      }));
      const favouritesResponse = await getFavouriteProducts();
      if (favouritesResponse.status === 200) {  
        dispatch(setFavourites(favouritesResponse.data));
      }
      navigate('/');
    } else {
      dispatch(setSnackbar({
        variant: 'error',
        display: true,
        message: t('login_error')
      }));
    }
  };
  const handleGoToForgetPassword = () => {
    setStep(2);
  }
  const handleForgetPassword = async (data) => {
    const payload = {
      phone_number: '0' + data.mobile_number,
      otp_type: 'forget_password'
    }
    setData(data);
    const response = await sendOTP(payload);
    if (response.status === 200) {
      setStep(3);
    } else {
      dispatch(setSnackbar({
        display: true,
        variant: 'error',
        message: response.response.data.message
      }))
    }
  }
  const handleOTPSubmit = async () => {
    const payload = {
      password1: data.password1,
      password2: data.password2,
      otp: OTP
    };
    const response = await forgetPassword(payload);
    if (response.status === 200) {
      dispatch(setSnackbar({
        display: true,
        variant: 'success',
        message: response.data.message
      }))
      setStep(4)
    } else {
      dispatch(setSnackbar({
        display: true,
        variant: 'error',
        message: response.response.data.message
      }))
    }
  };
  const handleResendOTP = async () => {
    const payload = {
      phone_number: '0' + data.phone,
      otp_type: 'forget_password'
    }
    const response = await sendOTP(payload);
    return response
  };
  const handleGoToLogin = () => {
    setStep(1);
  }
  const handleBack = () => {
    if (step > 1) {
      setStep(prev => prev - 1);
    }
  }

  return (
    <main className={styles['login-container']}>
      <FormSplash />
      <div className={styles['login-aside']}>
        {
          step > 1 &&
          <button className={styles['login-back']} onClick={handleBack}>
            <img src={backArrow} alt='back' />
          </button>
        }
        {step === 1 &&
          <LoginForm handleForgetPassword={handleGoToForgetPassword} handleFormSubmit={handleLoginFormSubmit} />
        }
        {step === 2 &&
          <ForgetPasswordForm handleBack={handleBack} handleFormSubmit={handleForgetPassword}>
            <Button type='button' handleSubmit={handleGoToLogin} text={t('login.back')} variant='outline' />
          </ForgetPasswordForm>
        }
        {step === 3 &&
          <OTPStep handleOTPSubmit={handleOTPSubmit} setOTP={setOTP} handleResendOTP={handleResendOTP} handleBack={handleBack}>
            <Button type='button' handleSubmit={handleGoToLogin} text={t('login.back')} variant='outline' />
          </OTPStep>
        }
        {step === 4 &&
          <SuccessStep>
            <Button type='button' handleSubmit={handleGoToLogin} text={t('login.back')} />
          </SuccessStep>
        }
      </div>
    </main>
  )
}

export default Login;