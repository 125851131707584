import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function useHookForm(props) {
	const { t } = useTranslation();
	const { isFullName, isEmail, isRequiredEmail, isCity, isAddress, isMessage, isPhone, isRequiredPassword, isPassword, isConfirmPassword, isTerms } =
		props || {};

	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
		setValue,
		getValues,
		trigger,
		clearErrors,
	} = useForm({ mode: 'onBlur' });

	const phoneRegister =
		isPhone &&
		register('mobile_number', {
			onChange: (e) => {
				clearErrors('mobile_number');
				const phoneNumber = e.target.value;
				const trimmedNumber = phoneNumber.replace(/\D/g, '');
				setValue('mobile_number', trimmedNumber);
			},
			required: {
				value: true,
				message: t('form.mobile_required'),
			},
			validate: (value) => {
				const regex = /^1[0125][0-9]{8}$/;
				return regex.test(value) || t('form.mobile_invalid');
			},
		});
	const fullNameRegister =
		isFullName &&
		register('full_name', {
			onChange: (e) => {
				clearErrors('full_name');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('full_name', displayValue);
				} else {
					setValue('full_name', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('full_name');
				}
			},
			required: {
				value: true,
				message: t('form.fullname_required'),
			},
			maxLength: {
				value: 20,
				message: t('form.exceed'),
			},
		});

	const cityRegister =
		isCity &&
		register('city', {
			onChange: (e) => {
				clearErrors('city');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('city', displayValue);
				} else {
					setValue('city', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('city');
				}
			},
			required: {
				value: true,
				message: t('form.city_required'),
			},
			maxLength: {
				value: 20,
				message: t('form.exceed'),
			},
		});
	const addressRegister =
		isAddress &&
		register('address', {
			onChange: (e) => {
				clearErrors('address');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z0-9 \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z0-9 \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('address', displayValue);
				} else {
					setValue('address', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('address');
				}
			},
			required: {
				value: true,
				message: t('form.address_required'),
			},
			maxLength: {
				value: 50,
				message: t('form.exceed'),
			},
		});

	const emailRegister =
		isEmail &&
		register('email', {
			onChange: (e) => {
				clearErrors('email');
				setValue('email', e.target.value);
				clearErrors('email');
				if (!e.target.value) {
					resetField('email');
				}
			},
			validate: (value) => {
				if (!value) {
					return true;
				} else {
					const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
					return regex.test(value) || t('form.email_invalid');
				}
			},
		});
	const requiredEmailRegister =
		isRequiredEmail &&
		register('email', {
			onChange: (e) => {
				clearErrors('email');
				setValue('email', e.target.value);
				clearErrors('email');
				if (!e.target.value) {
					resetField('email');
				}
			},
			required: {
				value: true,
				message: t('form.email_required'),
			},
			validate: (value) => {
				if (!value) {
					return true;
				} else {
					const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
					return regex.test(value) || t('form.email_invalid');
				}
			},
		});
	const requiredPasswordRegister =
		isRequiredPassword &&
		register('password1', {
			onChange: (e) => {
				clearErrors('password1');
				setValue('password1', e.target.value);
				clearErrors('password1');
				if (!e.target.value) {
					resetField('password1');
				}
			},
			required: {
				value: true,
				message: t('form.password_required'),
			},
			maxLength: {
				value: 40,
				message: t('form.exceed'),
			},
		});
	const passwordRegister =
		isPassword &&
		register('password1', {
			onChange: (e) => {
				clearErrors('password1');
				setValue('password1', e.target.value.trim());
				clearErrors('password1');
				trigger('password2');
				if (!e.target.value) {
					resetField('password1');
				}
			},
			required: {
				value: true,
				message: t('form.password_required'),
			},
			validate: (value) => {
				const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
				return regex.test(value) || t('form.password_invalid');
			},
		});
	const confirmPasswordRegister =
		isConfirmPassword &&
		register('password2', {
			onChange: (e) => {
				clearErrors('password1');
				setValue('password2', e.target.value);
				clearErrors('password2');
				if (!e.target.value) {
					resetField('password2');
				}
			},
			required: {
				value: true,
				message: t('form.password_required'),
			},
			validate: (value) => {
				return getValues('password1') === value || t('form.password_not_match');
			},
		});
	const termsRegister =
		isTerms &&
		register('accept-terms', {
			required: {
				value: true,
				message: '*',
			},
		});
	const messageRegister =
		isMessage &&
		register('message', {
			required: {
				value: true,
				message: t('form.message_required'),
			},
		});
	return {
		handleSubmit,
		errors,
		isDirty,
		isValid,
		emailRegister,
		phoneRegister,
		fullNameRegister,
		passwordRegister,
		requiredEmailRegister,
		requiredPasswordRegister,
		confirmPasswordRegister,
		termsRegister,
		messageRegister,
		cityRegister,
		addressRegister,
		getValues,
		dirtyFields,
		isSubmitting,
		clearErrors,
		setValue,
		trigger,
	};
}
