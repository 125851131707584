import { useEffect, useState } from "react";
import styles from './index.module.scss';

export function ImageViewer({ images, thumbnail, name }) {
  const [selectedImage, setSelectedImage] = useState(thumbnail);
  const [thumbs, setThumbs] = useState(null);
  
  const handleImageSelect = (image) => {
    setSelectedImage(image.url);
    const newThumbs = thumbs.map(thumb => {
      if (thumb.id === image.id) {
        return {
          ...thumb,
          isThumb: true
        }
      } else {
        return {
          ...thumb,
          isThumb: false
        }
      }
    })
    setThumbs(newThumbs)
  };

  useEffect(() => {
    setThumbs([
      ...(images.map(image => {
        return { ...image, isThumb: false }
      })),
      {
        id: images.length + 1,
        name: name,
        url: thumbnail,
        isThumb: true,
      }
    ]);
  }, []);

  return (
    <div className={styles['image-viewer']}>
      <div className={styles['thumbnail']}>
        <img src={selectedImage} className={styles['thumbnail-image']} alt="thumbnail"/>
      </div>
      <div className={styles['image-viewer-thumbs']}>
        {
          thumbs?.map(image => {
            if (!image.isThumb) {
              return <img onClick={() => handleImageSelect(image)} key={image.id} className={styles['thumb']} src={image.url} alt={image.name} />
            }
            return;
          })
        }
      </div>
    </div>
  )
}

export default ImageViewer;