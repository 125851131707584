import LanguageSwitcher from "../languageSwitcher";
import styles from './index.module.scss';
import logo from '../../assets/images/logo.png';
import success from '../../assets/images/success.png';
import { useTranslation } from "react-i18next";

export function SuccessStep({ children }) {
  const { t } = useTranslation();

  return (
    <section className={styles['success']}>
      <img className={styles['success-logo']} src={logo} alt='logo' />
      <div className={styles['success-content']}>
        <img className={styles['success-content-image']} src={success} alt="successfull" />
        <p className={styles['success-content-body']}>{t('change_password_successfull')}</p>
        <div className={styles['success-actions']}>
          {children}
          <div className={styles['language-switcher-container']}>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessStep;
