import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const getPaymentMethods = async () => {
	return axios
		.get('/api/ecommerce/payment-methods', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const checkout = async (payload) => {
	return axios
		.post('/api/ecommerce/execute-payment/', payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const checkoutByBalance = async (slug, payload) => {
	return axios
		.post(`api/ecommerce/products/${slug}/initiate-payment`, payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};
